import React from 'react'

function Featured() {
    return (
        <section className="featured-section bg-white hidden">
            <div className="container mx-auto px-2 md:px-0">
                <h2 className='text-4xl text-blue-600 text-center tracking-widest mb-12' data-aos="zoom-out" data-aos-delay="200">FEATURED IN</h2>
                <div className="grid grid-cols-1 sm:gric-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5" data-aos="zoom-in" data-aos-delay="200">
                    <div className='mx-auto'>
                        <img src={require("../../images/Home/feature01.png")} alt="" />
                    </div>
                    <div className='mx-auto'>
                        <img src={require("../../images/Home/feature02.png")} alt="" />
                    </div>
                    <div className='mx-auto'>
                        <img src={require("../../images/Home/feature03.png")} alt="" />
                    </div>
                    <div className='mx-auto'>
                        <img src={require("../../images/Home/feature04.png")} alt="" />
                    </div>
                    <div className='mx-auto'>
                        <img src={require("../../images/Home/feature07.png")} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Featured