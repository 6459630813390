import React from 'react'
import { Link } from 'react-router-dom'

function Section03() {
    return (
        <>
            <section className='section-03 mt-12' style={{
                background: `url(${require("../../images/Home/bg-map-grey-scaled.jpg")})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                clipPath: "polygon(0% 20%, 100% 0%, 100% 100%, 0px 100%)"
            }}>
                <div className='pt-16 hidden md:block'>
                    <img src={require("../../images/Home/arrow-down.png")} alt="" className='m-auto mt-[-3px]' />
                </div>
                <div className="container mx-auto px-2 md:px-0 pt-40 md:pt-0">
                    <div className="wrapper pb-24 pt-80 md:pt-12 grid grid-cols-1 md:grid-cols-2 gap-16 items-center">
                        <div className='flex flex-col md:flex-row overflow-hidden md:overflow-auto' data-aos="fade-zoom-in" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="100">
                            <div style={{
                                background: `url(${require("../../images/Home/tailor.jpg")})`,
                                backgroundSize: "cover",
                                backgroundPosition: "left center",
                                backgrondRepeat: "no-repeat"
                            }} className="basis-1/3 p-[15rem] md:p-0">

                            </div>
                            <div style={{
                                background: "linear-gradient(90deg, rgb(237, 237, 237), white)"
                            }}
                                className="py-8 px-4 basis-2/3">
                                <h2 className='text-4xl font-medium'>
                                    <span className='text-blue-600 block'>WE CAN <br /> RESOLVE</span>
                                    YOUR DEBT
                                </h2>
                                <ul className='my-8'>
                                    <li>
                                        <span className='h-[15px] w-[15px] inline-block rounded bg-blue-600 mr-2 opacity-40'></span>
                                        <span className='text-lg font-medium'>Credit Cards</span>
                                    </li>
                                    <li>
                                        <span className='h-[15px] w-[15px] inline-block rounded bg-blue-600 mr-2 opacity-40'></span>
                                        <span className='text-lg font-medium'>Personal Loans and Lines of Credit</span>
                                    </li>
                                    <li>
                                        <span className='h-[15px] w-[15px] inline-block rounded bg-blue-600 mr-2 opacity-40'></span>
                                        <span className='text-lg font-medium'>Medical Bills</span>
                                    </li>
                                    <li>
                                        <span className='h-[15px] w-[15px] inline-block rounded bg-blue-600 mr-2 opacity-40'></span>
                                        <span className='text-lg font-medium'>Collections and Repossessions</span>
                                    </li>
                                    <li>
                                        <span className='h-[15px] w-[15px] inline-block rounded bg-blue-600 mr-2 opacity-40'></span>
                                        <span className='text-lg font-medium'>Business Debts</span>
                                    </li>
                                </ul>
                                <Link to="/apply/0" className='py-1 md:py-2 px-3 md:px-7 w-full block bg-blue-600 text-white text-center text-sm md:text-xl rounded-full'>Click Here For Your Free Quote Now</Link>
                            </div>
                        </div>
                        <div className='bg-white py-24 px-6' data-aos="fade-right" data-aos-delay="200" style={{
                            clipPath: "polygon(0 15%,100% 0,100% 85%,0 100%)"
                        }}>
                            <h2 className="text-zinc-600 text-4xl font-medium mt-8"
                            >WE TAILOR A DEBT RELIEF PROGRAM THAT’S RIGHT FOR YOU.</h2>
                            <div className='mt-12'>
                                <div className="my-8 flex items-center">
                                    <div className="opacity-40 text-blue-600 mr-4">
                                        <h2 className='text-5xl font-medium'>1.</h2>
                                    </div>
                                    <div>
                                        <h2 className="text-3xl font-medium text-blue-600">
                                            CONTACT US
                                        </h2>
                                        <p className="text-xl">
                                            A Consumer Finance Agent will help you figure out which plan is best for you!
                                        </p>
                                    </div>
                                </div>
                                <div className="my-8 flex items-center">
                                    <div className="opacity-40 text-blue-600 mr-4">
                                        <h2 className='text-5xl font-medium'>2.</h2>
                                    </div>
                                    <div>
                                        <h2 className="text-3xl font-medium text-blue-600">
                                            GET A QUOTE
                                        </h2>
                                        <p className="text-xl">
                                            We will give you options, pick the one that works best for you!
                                        </p>
                                    </div>
                                </div>
                                <div className="my-8 flex items-center">
                                    <div className="opacity-40 text-blue-600 mr-4">
                                        <h2 className='text-5xl font-medium'>3.</h2>
                                    </div>
                                    <div>
                                        <h2 className="text-3xl font-medium text-blue-600">
                                            START SAVING
                                        </h2>
                                        <p className="text-xl">
                                            Reduce debt while saving money with a lower monthly payment.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Section03