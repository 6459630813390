import React, { useState } from 'react'
import { Link } from "react-router-dom"

function Hero() {

    const [amount, setAmount] = useState(17500)

    return (
        <section className="hero-section" style={{ background: `url(${require("../../images/Home/hero01.jpg")})`, backgroundSize: "cover", backgroundPosition: "top center", backgroundRepeat: "no-repeat" }}>
            <div className="bg-color">
                <div className="container mx-auto px-2 md:px-0 py-20">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                        <div className="order-2 md:order-1 z-50">
                            <div className="mb-4 p-7 rounded shadow text-center " data-aos="fade-down-right" style={{
                                background: "rgba(255,255,255,.75)",
                                clipPath: "polygon(0 0,100% 25%,100% 100%,0 75%)",
                                padding: "80px 60px"
                            }}>
                                <h2 className="text-3xl text-blue-600 font-medium">EMPOWERING EVERYDAY AMERICANS IN ACHIEVING FINANCIAL FREEDOM WITH</h2>
                                <h2 className="text-3xl font-medium">JUST 3 SIMPLE STEPS!</h2>
                            </div>
                            <div className="rounded shadow" data-aos="fade-up-right" data-aos-delay="200" style={{
                                clipPath: "polygon(0px 0px, 100% 20%, 100% 100%, 0px 83%)",
                                background: "white",
                                padding: "80px 40px"
                            }}>
                                <h2 className="text-4xl text-blue-600 font-medium">WHAT IS YOUR TOTAL DEBT?</h2>
                                <p className='text-xl'>See your relief options in as little as 10 minutes. Do not wait for tomorrow, take action now.</p>
                                <div className='my-4'>
                                    <div className='flex'>
                                        <span className='text-sm pr-2'>$3,500</span>
                                        <input type="range" min="3500" max="80000" value={amount} className="range range-xs" onChange={(e) => setAmount(e.target.value)} />
                                        <span className='text-sm pl-2'>$80,000</span>
                                    </div>
                                    <h2 className='text-3xl'>${amount}</h2>
                                </div>
                                <Link to={`/apply/${amount}`} className="rounded-full py-1 md:py-3 px-3 md:px-5 w-full text-center bg-blue-700 text-white block text-sm md:text-2xl">Click Here to see your options</Link>
                            </div>
                        </div>
                        <div className="order-1 md:order-2 h-[20rem] md:h-0">
                            <img src={require("../../images/Home/hero01.jpg")} alt='' className='block md:hidden' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero