import React from 'react'
import HeroSection from '../components/HeroSection'
import Section02 from '../components/PrivacyPolicy/Section02'
import heroBgImg from "../images/Home/bg-map-grey-scaled.jpg"

function PrivacyPolicy() {

    const title = (
        <>
            PRIVACY POLICY
        </>
    )

    return (
        <>
            <HeroSection bgImg={heroBgImg} title={title}></HeroSection>
            <Section02></Section02>
        </>
    )
}

export default PrivacyPolicy