import React from 'react'
import { Link } from "react-router-dom"
import { FaPhone } from "react-icons/fa"
import Logo from '../images/Home/NewWestFinancialWhite.png';

import './header.css'

function Header() {

    const MenuItemsLink = "border-3 text-xl hover:text-blue-500 hover:border-blue-500 menu-item"


    const MenuItems = (
        <>
            <li className={MenuItemsLink}>
                <Link to="/#HeroSection">Home</Link>
            </li>
            <li className={MenuItemsLink}>
                <Link to="/why-us#HeroSection">Why Choose Us?</Link>
            </li>
            <li tabIndex={0}>
                <button className={`${MenuItemsLink} justify-between hover:bg-transparent`}>
                    <span className='text-sm md:text-xl'>Options</span>
                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" /></svg>
                </button>
                <ul className="p-2 bg-white shadow rounded">
                    <li className={MenuItemsLink}><Link to="/mortgage-personal-loans" className='dropdown-item'>Mortgage & Personal Loans</Link></li>
                    <li className={MenuItemsLink}><Link to="/debt-resolution" className='dropdown-item'>Debt Resolution</Link></li>
                    <li className={MenuItemsLink}><Link to="/debt-settlement" className='dropdown-item'>Debt Settlement</Link></li>
                    <li className={MenuItemsLink}><Link to="/bankruptcy" className='dropdown-item'>Bankruptcy</Link></li>
                </ul>
            </li>
            <li tabIndex={0}>
                <button className={`${MenuItemsLink} justify-between hover:bg-transparent`}>
                    <span className='text-sm md:text-xl'>About</span>
                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                    </svg>
                </button>
                <ul className="p-2 bg-white shadow rounded">
                    <li className={MenuItemsLink}><Link to="/about-us" className='dropdown-item'>About Us</Link></li>
                    <li className={MenuItemsLink}><Link to="/terms-of-use" className='dropdown-item'>Terms of Use</Link></li>
                    <li className={MenuItemsLink}><Link to="/privacy-policy" className='dropdown-item'>Privacy Policy</Link></li>
                </ul>
            </li>
            <li className={MenuItemsLink}><Link to="/faq">FAQs</Link></li>
            <li className={MenuItemsLink}><Link to="/contact-us">Contact</Link></li>
        </>
    )

    return (
        <>
            <section className='relative pb-[5rem]'>
                <header className='py-2 fixed top-0 left-0 right-0 bg-white shadow-lg z-[9999]'>
                    <div className="container mx-auto p-0 md:px-2">
                        <div className="wrapper">
                            <div className="navbar bg-base-100">
                                <div className="navbar-start">
                                    <div className="dropdown">
                                        <label tabIndex={0} className="btn btn-ghost lg:hidden">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                                        </label>
                                        <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                                            {MenuItems}
                                        </ul>
                                    </div>
                                    <Link to="/" className="text-md">
                                        <img src = {Logo} height = "auto"
                                             width = "60" alt = "New West Financial"/>
                                    </Link>
                                </div>
                                <div className="navbar-center hidden lg:flex">
                                    <ul className="menu menu-horizontal p-0">
                                        {MenuItems}
                                    </ul>
                                </div>
                                <div className="navbar-end">
                                    <a href="tel:855-FIN-WEST" className="py-1 px-5 rounded-full flex justify-between items-center text-blue-500 hover:text-white hover:bg-blue-500 border-2 border-blue-500 transition duration-150 ease-out">
                                        <FaPhone className='pr-2' />
                                        <span >855-FIN-WEST</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </section>
        </>
    )
}

export default Header