import React from 'react'
import { Link } from "react-router-dom"

function HeroSection({ bgImg, title }) {
    return (
        <section className="hero-section" id='HeroSection' style={{
            background: `url(${bgImg})`,
            backgroundSize: "cover",
            backgroundPosition: "top center",
            backgroundRepeat: "no-repeat"
        }}>
            <div className="container mx-auto ">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    <div className='py-24 pl-12 pr-28 md:pr-40' data-aos="fade-up-right" data-aos-delay="200" style={{
                        background: "linear-gradient(90deg,white 0%,rgba(255,255,255,.75) 15%,rgba(255,255,255,.5) 30%)",
                        clipPath: "polygon(0 0,100% 0,70% 100%,0% 100%)"
                    }}>
                        <h2 className='text-3xl md:text-6xl pl-4 border-l-4 border-l-blue-600 font-semibold'>
                            {title && title}
                        </h2>
                        <h4 className='text-xl md:text-2xl font-semibold mt-14 mb-8'>
                            <span>Call us at </span>
                            <a href="tel:855-FIN-WEST" className='text-blue-600'> 855-FIN-WEST</a>
                        </h4>
                        <Link to="/contact-us" className='text-uppercase text-xl md:text-2xl bg-blue-700 font-semibold block max-w-[400px] w-full pt-1 md:pt-2 pb-2 md:pb-3 px-5 rounded-full text-white text-center'>Learn More</Link>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className="">
                <img src={require("../images/divider-grunge-hor.png")} alt="" className='w-full' />
            </div>
        </section>
    )
}

export default HeroSection