import React from 'react'
import { FaCheckCircle } from "react-icons/fa"

function WhyUsSection02() {
    return (
        <section className="why-us-section-02 my-12">
            <div className="container mx-auto px-2 md:px-0 max-w-5xl w-full">
                <h2 className="text-3xl md:text-5xl font-semibold text-blue-600 text-center tracking-widest mb-8" data-aos="fade-left" data-aos-delay="200">
                    BENEFITS OF WORKING WITH New West Financial
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-24">
                    <div>
                        <h3 className='text-xl mb-4 opacity-75' data-aos="fade-right" data-aos-delay="200">
                            <FaCheckCircle className='mr-4 text-blue-600 inline' />
                            <span className='text-zinc-500'>
                                A dedicated Consumer Finance Agent will work with you to make sure you understand the process and all your options. There are many financial services and products out there. Our Agents are here to advise you of what is possible, and the best way to accomplish your goals.
                            </span>
                        </h3>
                        <h3 className='text-xl mb-4 opacity-75' data-aos="fade-right" data-aos-delay="200">
                            <FaCheckCircle className='mr-4 text-blue-600 inline' />
                            <span className='text-zinc-500'>
                                Our partners have decades of experience vending the products and services we help deliver. New West Financial has gone to great lengths to vet our partners and make sure you get the best products and services on the market today.
                            </span>
                        </h3>
                        <h3 className='text-xl mb-4 opacity-75' data-aos="fade-right" data-aos-delay="200">
                            <FaCheckCircle className='mr-4 text-blue-600 inline' />
                            <span className='text-zinc-500'>
                                Proven Track Record. Take a look at our testimonials. Speak to one of our Agents to fully understand what we do, and how we do it.
                            </span>
                        </h3>
                    </div>
                    <div>
                        <h3 className='text-xl mb-4 opacity-75' data-aos="fade-right" data-aos-delay="200">
                            <FaCheckCircle className='mr-4 text-blue-600 inline' />
                            <span className='text-zinc-500'>
                                Customer benefit is a must! We can not offer any product or service that does not have a clear benefit to the customer.
                            </span>
                        </h3>
                        <h3 className='text-xl mb-4 opacity-75' data-aos="fade-right" data-aos-delay="200">
                            <FaCheckCircle className='mr-4 text-blue-600 inline' />
                            <span className='text-zinc-500'>
                                Consumers are our #1 priority. With full transparency, our goal is for our customers to make an educated decision of what is best for their circumstance.
                            </span>
                        </h3>
                        <h3 className='text-xl mb-4 opacity-75' data-aos="fade-right" data-aos-delay="200">
                            <FaCheckCircle className='mr-4 text-blue-600 inline' />
                            <span className='text-zinc-500'>
                                No matter what option you choose, the entire idea is to have one low monthly payment that is manageable.
                            </span>
                        </h3>
                        <h3 className='text-xl mb-4 opacity-75' data-aos="fade-right" data-aos-delay="200">
                            <FaCheckCircle className='mr-4 text-blue-600 inline' />
                            <span className='text-zinc-500'>Our Online Client Portal gives you 24/7 access to all of your account information to monitor and check on your progress</span>
                        </h3>
                        <h3 className='text-xl mb-4 opacity-75' data-aos="fade-right" data-aos-delay="200">
                            <FaCheckCircle className='mr-4 text-blue-600 inline' />
                            <span className='text-zinc-500'>Customer Relations Specialists are available to support by phone, email, or chat to answer any questions you may have and to manage your account</span>
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyUsSection02