import React from 'react'
import HeroSection from "../components/HeroSection"
import BannerSection from '../components/BannerSection'
import ContactSection from '../components/ContactSection'
import Section02 from '../components/MortgagePersonalLoans/Section02'

import heroBgImg from "../images/MortgagePersonalLoans/bg-debt-cons.jpg"
import bannerBgImg from "../images/MortgagePersonalLoans/dc-content.jpg"

function MortgagePersonalLoans() {

    const title = (
        <>
            MORTGAGE &<br />  PERSONAL<br />
            <span className='text-blue-600'>LOANS</span>
        </>
    )

    const bannerContent = (
        <>
            <div className='pl-4 border-l-4 border-l-white text-white'>
                <h2 className="text-3xl font-medium tracking-widest">
                    IS DEBT <br /> CONSOLIDATION <br /> RIGHT FOR ME?
                </h2>
                <p className="text-lg font-medium mt-10 mb-6">
                    While combining your debt should not be viewed as a silver bullet that will fix your debt situation, it will allow you the chance to get caught up in certain circumstances. To begin with, debt consolidation does not work if you are buried in debt with no chance to repay that obligation. Debt consolidation success requires the following conditions:
                </p>
                <div className="mt-6 pl-6">
                    <ul>
                        <li className='flex items-center my-1'>
                            <span className="inline-block h-4 w-4 rounded bg-white mr-2"></span>
                            <span className='text-lg font-medium'>Total debt does not exceed 50% of Income</span>
                        </li>
                        <li className='flex items-center my-1'>
                            <span className="inline-block h-4 w-4 rounded bg-white mr-2"></span>
                            <span className='text-lg font-medium'>Credit rating still qualifies for 0% Credit Card /Low Interest consolidation loan</span>
                        </li>
                        <li className='flex items-center my-1'>
                            <span className="inline-block h-4 w-4 rounded bg-white mr-2"></span>
                            <span className='text-lg  font-medium'>Your income consistently allows for the payment of your monthly bills</span>
                        </li>
                        <li className='flex items-center my-1'>
                            <span className="inline-block h-4 w-4 rounded bg-white mr-2"></span>
                            <span className='text-lg  font-medium'>You have a spending solution designed to avoid running up debt again</span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )

    return (
        <>
            <HeroSection bgImg={heroBgImg} title={title} ></HeroSection>
            <Section02></Section02>
            <BannerSection bgImg={bannerBgImg}></BannerSection>
            <ContactSection paddingTop="52" marginTop="mt-[-11rem]"></ContactSection>
        </>
    )
}

export default MortgagePersonalLoans