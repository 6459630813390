import React from 'react'

function Section02() {
    return (
        <section className="terms-of-use-section my-12">
            <div className="container mx-auto max-w-3xl w-full px-2 md:px-0">
                <div>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Privacy Policy
                    </h3>
                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Last Updated: 10/10/2022
                    </h3>
                    <p className='text-xl text-zinc-700 mb-2'>
                        This Online Privacy Policy applies to www.New West Financial.Financial, owned and operated by New West Financial (sometimes referred to as “Company”, “we” or “us”), and any Company affiliate or subsidiary online interface that links to this policy, (each, a “Site,” and collectively, the “Sites”).
                    </p>
                    <p className='text-xl text-zinc-700 mb-6'>
                        We take our ethical responsibilities and your privacy seriously. We have a strong commitment to providing excellent service to all our customers and visitors to this Web Site, including respecting your concerns about privacy. This Privacy Policy discloses how we collect, protect, use and share information gathered about you on our website. If you use this site you explicitly agree to the terms and conditions of the Privacy Policy in effect at the time of your use. We hope that this disclosure will help increase your confidence in our website and enhance your experience on the Internet.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Browsing
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        This website does not collect personally identifiable information from your computer when you browse this website and request pages from our servers. This means that, unless you voluntarily and knowingly provide us with personally identifiable information, we will not know your name, your email address, or any other personally identifiable information. We may use IP addresses, browser types and access times to analyze trends, administer the site, improve site performance and gather broad demographic information for aggregate use. When you request a page from our website, our servers log the information provided in the HTTP request header including the IP number, the time of the request, the URL of your request, and other information that is provided in the HTTP header. We collect the HTTP request header information in order to make our website function correctly and provide you the functionality that you see on this website. We also use this information to better understand how visitors use our website and how we can better tune it, its contents and functionality to meet your needs.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Call Monitoring
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        Please note that all calls with the Company may be recorded or monitored for quality assurance and training purposes.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Information We Collect and Its Uses
                    </h3>
                    <p className='text-xl text-zinc-700 mb-2'>
                        We collect your personal information if you decide to purchase one of our products or retain our services, participate in our affiliate marketing program, subscribe to our newsletter, complete an application form, participate in one of our surveys or transact other business with us. We need to collect personally identifiable information from you to execute a requested transaction, provide you with a particular service, and/or to further enhance your account. At anytime, we may ask you to voluntarily supply us with additional information needed. We will ask you for information such as, but not limited to: name, current and/or billing address, your e-mail address, telephone number and, if you purchase one of our products or services, a valid credit card number, your Social Security number and certain other personal information, such as your date of birth, address, financial information, and certain credit card and loan account information. We may use your email address to send a confirmation and, if necessary, we might use the other information to contact you for help in processing.
                    </p>
                    <p className='text-xl text-zinc-700 mb-2'>
                        In addition, when you provide contact details for transaction requests such as scheduling an appointment or requesting a proposal, we will use the contact information to keep you updated about future offers or promotions, unless you opt-out online or otherwise notify us.
                    </p>
                    <p className='text-xl text-zinc-700 mb-6'>
                        We may also use the information we collect about you in order to, but not limited to:
                    </p>


                    <p className='text-xl text-zinc-700 mb-6'>
                        As mentioned above, we may use your personally identifiable information in many other ways, including sending you promotional materials, and providing your information to third parties as directed by you. (By “promotional materials,” we mean communications that directly promote the use of Web sites, or the purchase of products or services.). However, you may “opt-out” of certain uses of your personal information.
                    </p>



                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Disclosure of Information to Third Parties
                    </h3>
                    <p className='text-xl text-zinc-700 mb-2'>
                        We may disclose a consumer’s personally identifiable information in order to effect or carry out any transaction that you have requested of us or as necessary to complete our contractual obligations with you. WE RESERVE THE RIGHT TO SELL, RENT OR TRANSFER YOUR PERSONAL INFORMATION TO THIRD PARTIES FOR ANY PURPOSE IN OUR SOLE DISCRETION.
                    </p>
                    <p className='text-xl text-zinc-700 mb-2'>
                        We do not sell or transfer of personal information to non-affiliated entities for their use unless you have directed us to do so. We may share your personally identifiable information with affiliated companies that are directly or indirectly controlled by, or under common control of New West Financial. When directed by you, we may send personally identifiable information about you to non-affiliated companies that are not directly or indirectly controlled by, or under common control of, New West Financial.
                    </p>
                    <p className='text-xl text-zinc-700 mb-6'>
                        The personal information collected on this site and by third parties will be used to operate the site and to provide the services or products or carry out the transactions you have requested or authorized. We may change or broaden the use of your personal information at any time. We may use your personal information to provide promotional offers to individuals by means of email advertising, telephone marketing, direct mail marketing, banner advertising, and other possible uses.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Service Providers
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        We may use other third parties to provide certain clerical, processing, and shipping services or to assist in providing the services that you requested. As such, we may share your personally identifiable information with service providers as necessary to carry out these tasks. When you contract with us, we will share only as much information with our service providers as is necessary for the provision of their services. These third parties are prohibited from using your personally identifiable information for any other purpose other than carrying out their contract services.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Choice/Opt-out
                    </h3>
                    <p className='text-xl text-zinc-700 mb-2'>
                        We provide you the opportunity to ‘opt-out’ of having your personally identifiable information used for certain purposes, when we ask for this information. For example, if you purchase a product/service but do not wish to receive any additional marketing material from us, you can indicate your preference on our order form.
                    </p>
                    <p className='text-xl text-zinc-700 mb-2'>
                        If you no longer wish to receive our newsletter and promotional communications, you may opt-out of receiving them by following the instructions included in each newsletter or communication or by contacting us using the contact information below.
                    </p>
                    <p className='text-xl text-zinc-700 mb-6'>
                        If you do not wish to have your personal information shared or disclosed to any third-party that is not our agent/service provider, please contact our customer service department to actively opt-out of having your personal information shared. Customer Service Contact Information: Phone: 855-FIN-WEST.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Securing the Transmission and Storage of Information
                    </h3>
                    <p className='text-xl text-zinc-700 mb-2'>
                        The security of your personal information is extremely important to us. We employ generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it.
                    </p>
                    <p className='text-xl text-zinc-700 mb-2'>
                        Our site uses SSL (Secure Sockets Layer) security technology to encrypt information you provide to us through the site when entering sensitive information (such as credit card number and/or social security number) on our registration or order forms. This ensures that your information is encrypted as it travels over the Internet. SSL is an industry-standard protocol for encryption over the internet.
                    </p>
                    <p className='text-xl text-zinc-700 mb-2'>
                        After information reaches New West Financial, it is stored on a secure server that resides behind firewalls designed to block unauthorized access from outside of New West Financial. You can help to maintain the security of your online transactions by not sharing your personal information or password with anyone. Remember, no method of transmission over the Internet, or method of electronic storage, is 100% secure. (Example: any information you provide us by email is not encrypted) Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
                    </p>
                    <p className='text-xl text-zinc-700 mb-6'>
                        If you have any questions about the security on our Web site, please feel free to e-mail us.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Supplementation of Information
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        In order to provide certain services to you, we may, on occasion, supplement the personal information you submitted to us with information from third-party sources.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Log Files
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        As is true of most Web sites, we gather automatically certain information and store it in log files. This information includes internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and click stream data.
                        <br />
                        We use this information, which does not identify individual users, to analyze trends, to administer the site, to track users’ movements around the site and to gather demographic information about our user base as a whole. We do not link this automatically collected data to personally identifiable information.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Cookies
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        A cookie is a small text file that is stored on a user’s computer for record-keeping purposes. We use cookies on this site. We do not link the information we store in cookies to any personally identifiable information you submit while on our site.
                        <br />
                        We use session ID cookies. We use session cookies to make it easier for you to navigate our site. A session ID cookie expires when you close you browser. We can at your option set a persistent cookie to store your passwords, so you don’t have to enter it more than once. Persistent cookies also enable us to track and target the interests of our users to enhance the experience on our site.
                        <br />
                        If you reject cookies, you may still use our site, but your ability to use some areas of our site will be limited.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Clear Gifs (Web Beacons/Web Bugs)
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        We employ a software technology called clear gifs (a.k.a. Web Beacons/Web Bugs), that help us better manage content on our site by informing us what content is effective. Clear gifs are tiny graphics with a unique identifier, similar in function to cookies, and are used to track the online movements of Web users. In contrast to cookies, which are stored on a user’s computer hard drive, clear gifs are embedded invisibly on Web pages and are about the size of the period at the end of this sentence. We do not tie the information gathered by clear gifs to our customers’ personally identifiable information.
                        <br />
                        We use clear gifs in our HTML-based emails to let us know which emails have been opened by recipients. This allows us to gauge the effectiveness of certain communications and the effectiveness of our marketing campaigns. If you would like to opt-out of these emails, please see “Choice and Opt-out.”
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Links to Other Sites
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        This Website may contain links to other sites such as our affiliates as well as third parties which are not owned or controlled by us. Please be aware that this privacy policy only addresses our company’s use and disclosure of your information collected on this site.
                        <br />
                        While we try to link only to sites that share our standards and respect for privacy, we are not responsible for the privacy practices of such other sites. We encourage you to be aware when you leave our site and to read the applicable privacy policies and terms of conditions of each and every Web site that collects personally identifiable information.
                        <br />
                        This privacy statement applies only to information collected by this Web site.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Co-branded sites
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        We may be co-branded with “partners and affiliates”. These business to business relationships are helpful to us, and to you, as they afford all concerned with greater product and service opportunities. We always provide opt-out opportunities regarding the sharing of your information with such partners and affiliates. Please note the sites linked to our site are governed by their own privacy policies which may or may not reach the standards set by our company.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Access to Personally Identifiable Information
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        If your personally identifiable information changes, or if you no longer desire our product or service, you may correct, update, delete or deactivate same by emailing our Customer Support team. Or, if you prefer, you can contact us by telephone or postal mail.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Customer Service
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        If you send us correspondence, including e-mails and faxes, we may retain such information in your customer file. Information you give us over the telephone may be noted for your file. We may also keep copies of any correspondence sent to you. We retain these records in order to provide the products and services you have requested and to measure and improve our customer service. We may, over time, delete these records as permitted by law. Phone calls may be recorded or monitored for customer satisfaction purposes.
                        <br />
                        Based upon the personally identifiable information you provide, we may communicate with you to provide the services you request, and to manage your account. We may communicate via email or telephone, in accordance with your wishes.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Legal Disclaimer
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        We reserve the right to disclose your personally identifiable information, as required, to comply with the law, applicable regulations, governmental and quasi-governmental requests, judicial proceedings, court orders or subpoenas, to enforce our Legal Notices or other agreements, or to protect our rights, property or safety or the rights, property or safety of our users or others (e.g., to a consumer reporting agency for fraud protection etc.).
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Business Transitions
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        In the event our company goes through a business transition, such as a merger, acquisition by another company, or sale of all or a portion of its assets, your personally identifiable information will likely be among the assets transferred. You will be notified via e-mail and/or a prominent notice on our Web site for 30 days of any such change in ownership or control of your personal information.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Changes to this Privacy Statement
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        We may update this policy from time to time as our information practices are modified or changed. If we make changes to the Privacy Notice, we will revise the “Last Updated” date at the top of this Notice. Any changes to this Notice will become effective when we post the revised Notice on the Site. Your use of the Site following these changes means that you accept the revised Notice. The provisions contained in this privacy statement supersede all previous notices or policies regarding our privacy practices with respect to this site.
                        <br />
                        We encourage you to check our site frequently to see the current privacy statement and/or to be informed of how we are committed to protecting your information.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Testimonials
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        We display personal testimonials of satisfied customers on our website in addition to other endorsements. With your consent, we may post your testimonial along with your name. If you wish to update or delete your testimonial, you can contact us using the contact information below.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Notice to California Residents
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        If you are a California resident, California law may provide you with additional rights concerning New West Financial’s collection and use of your personal information. After reading this Online Privacy Policy, please click here to visit AcuFiNew West Financials California Privacy Notice (AIP.Financial/CCPA-Privacy-Notice) to learn more about your California Privacy Rights.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Notice to Vermont Residents
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        In response to Vermont regulations, we automatically treat accounts with Vermont billing addresses as if you requested that we not share your information with nonaffiliated third parties, and that we limit the information we share with our affiliates. If we disclose information about you to nonaffiliated third parties with whom we have joint marketing agreements, we will only disclose your name, address, other contact information, and information about our transaction and experiences with you.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        Notice to Nevada Residents
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        We are providing you this notice pursuant to state law. You may be placed on our internal Do Not Call List by requesting that we cease calling you by contacting us directly and making such request in writing at support@AIP.Financial. Nevada law requires that we also provide you with the following contact information: Bureau of Consumer Protection, Office of the Nevada Attorney General, 555 E. Washington St., Suite 3900, Las Vegas, NV 89101; Phone number: 702-486-3132; e-mail: BCPINFO@ag.state.nv.us.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mt-6 mb-6 font-semibold'>
                        Please print and retain a copy of this privacy policy for your records
                    </h3>

                </div>
            </div>
        </section>
    )
}

export default Section02