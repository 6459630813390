import React from 'react'

function Section02() {
    return (
        <section className="terms-of-use-section my-12">
            <div className="container mx-auto max-w-3xl w-full px-2 md:px-0">
                <div>
                    <p className='text-xl text-zinc-700 mb-6'>
                        By using this website (the ”Site”) you automatically agree to the terms and conditions expressed here.
                        <span className='font-bold'>
                            Please take a moment to look them over and, if you do not agree, please do not use our website
                        </span>
                        . However, for those who do use our site, we hope you find it to be an informative resource. We also reserve the right to make any modifications to our website that we deem necessary at any time. Your continued use of our site indicates that you accept the changes that are made.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        CHANGES TO THESE TERMS OF USE
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        We may make changes to the Terms of Use from time to time. If we do this, we will post the changed Terms of Use on the Site and will indicate at the top of this page the date the Terms of Use were last revised. You understand and agree that your continued use of the Service or the Site after we have made any such changes constitutes your acceptance of the new Terms of Use.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        CALL RECORDING
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        You agree that New West
                        Financial and or agents, representatives, affiliates or anyone calling on our behalf may contact you on a recorded line.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        TERMS APPLICABLE TO ALL USERS: ELIGIBILITY, LICENSE AND REPRESENTATION
                    </h3>
                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        ELIGIBILITY: GENERAL
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        This Site is intended solely for Users who, if they are a natural person, are eighteen (18) years of age or older, and any registration by, use of or access to the Site by any natural person under 18 is unauthorized, unlicensed and in violation of these Terms of Use. By using the Service or the Site, you represent and warrant that, if you are a natural person, you are 18 or older and that you agree to and will abide by all of the terms and conditions of the Terms of Use. If you violate any of these Terms of Use, or otherwise violate an agreement between you and us, the Company may prohibit you from using or accessing our Service or our Site (or any portion, aspect or feature of the Service or the Site), at any time in its sole discretion, with or without notice, including without limitation if it believes that you are under 18.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        HOUSEHOLD LIMITATION
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        Only one active loan per borrower may be outstanding at one time.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        INACTIVE APPLICATIONS
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        Loan applications are canceled after 14 days of inactivity.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        USER REPRESENTATION
                    </h3>
                    <p className='text-xl text-zinc-700 mb-6'>
                        You represent, warrant and agree that no materials of any kind submitted to or otherwise posted or shared by you through New West
                        Financial’s Site will violate or infringe upon the rights of any third party, including copyright, trademark, privacy, publicity or other personal or proprietary rights; or contain libelous, defamatory or otherwise unlawful material. You further agree not to harvest or collect email addresses or other contact information of Users from the Service or the Site by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications. Additionally, you agree not to use automated scripts to collect information from the Service or the Site for any other purpose. You further agree that you may not use the Service or the Site in any unlawful manner or in any other manner that could damage, disable, overburden or impair the Site. In addition, you agree not to use the our Services or the Site to:
                    </p>


                    <ul className='mt-6 mb-12'>
                        <li></li>
                    </ul>


                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        RESTRICTIONS ON USE OF OUR ONLINE MATERIALS
                    </h3>
                    <p className='text-xl text-zinc-700 mb-3'>
                        All Online Materials on the New West
                        Financial Site, including, without limitation, text, software, names, logos, trademarks, service marks, trade names, images, photos, illustrations, audio clips, video clips, and music are copyrighted intellectual property. All usage rights are owned and controlled by New West
                        Financial. You, the visitor, may download Online Materials for non-commercial, personal use only provided you 1) retain all copyright, trademark and propriety notices, 2) you make no modifications to the materials, 3) you do not use the materials in a manner that suggests an association with any of our products, services, events or brands, and 4) you do not download quantities of materials to a database, server, or personal computer for reuse for commercial purposes. You may not, however, copy, reproduce, republish, upload, post, transmit or distribute Online Materials in any way or for any other purpose unless you get our written permission first. Neither may you add, delete, distort or misrepresent any content on the New West
                        Financial site. Any attempts to modify any Online Material, or to defeat or circumvent our security features are prohibited.
                    </p>
                    <p className='text-xl text-zinc-700 mb-4'>
                        Everything you download, any software, plus all files, all images incorporated in or generated by the software, and all data accompanying it, is considered licensed to you by New West
                        Financial or third-party licensers for your personal, non-commercial home use only. We do not transfer title of the software to you. That means that we retain full and complete title to the software and to all of the associated intellectual-property rights. You are not allowed to redistribute or sell the material or to reverse-engineer, disassemble or otherwise convert it to any other form that people can use.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        ALL USERS: CONSENT TO ELECTRONIC TRANSACTIONS AND DISCLOSURES
                    </h3>
                    <p className='text-xl text-zinc-700 mb-3'>
                        Because our Company primarily operates on the Internet, it is necessary for you to consent to transact business with us online and electronically. As part of doing business with us, we also need you to consent to our giving you certain disclosures electronically, either via our Site or to the email address you provide to us.
                        By agreeing to the Terms of Use, you agree to receive electronically all documents, communications, notices, contracts, and agreements arising from or relating to your use of the Site and Service, including any loans you may request or receive, your registration as a borrower, any loans you may apply for and receive, your use of this Service, and the servicing of your loan, if funded, as a borrower (each, a Disclosure”), from us whether we are acting in the capacity as a loan broker or otherwise. The decision to do business with us electronically is yours. This document informs you of your rights concerning Disclosures.
                    </p>
                    <p className='text-xl text-zinc-700 mb-3'>
                        Electronic communications. Any disclosures will be provided to you electronically through our Site or via electronic mail to the verified email address you provided. If you require paper copies of such Disclosures, you may write to us at the mailing address provided below and a paper copy will be sent to you at a cost of up to $5.00. A request for a paper copy of any Disclosure will not be considered a withdrawal of your consent to receive Disclosures electronically. We may discontinue electronic provision of Disclosures in accordance with applicable federal and state record retention laws.
                    </p>
                    <p className='text-xl text-zinc-700 mb-3'>
                        Scope of consent. Your consent to receive Disclosures and transact business electronically, and our agreement to do so, applies to any transactions to which such Disclosures relate, whether between you and the Company. Your consent will remain in effect for so long as you are a User and, if you are no longer a User, will continue until such a time as all Disclosures relevant to transactions that occurred while you were a User have been made.
                    </p>
                    <p className='text-xl text-zinc-700 mb-3'>
                        Consenting to Do Business Electronically. Before you decide to do business electronically with Company, you should consider whether you have the required hardware and software capabilities described below.
                    </p>
                    <p className='text-xl text-zinc-700 mb-3'>
                        Hardware and Software Requirements. In order to access and retain Disclosures electronically, you must satisfy the following computer hardware and software requirements: access to the Internet; an email account and related software capable of receiving email through the Internet; supported Web browsing software (Chrome version 32.0 or higher, Firefox version 26- or higher, Internet Explore version 8.0 or higher, or Safari version 7.0 or higher); and hardware capable of running this software.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        TCPA Consent
                    </h3>
                    <p className='text-xl text-zinc-700 mb-3'>
                        I expressly consent to receiving calls and messages, including auto-dialed and pre-recorded message calls, and SMS messages (including text messages) from us, our affiliates, agents and others calling at their request or on their behalf, at any telephone numbers that you have provided or may provide in the future (including any cellular telephone numbers). Your cellular or mobile telephone provider will charge you according to the type of plan you carry.
                    </p>
                    <p className='text-xl text-zinc-700 mb-3'>
                        Additional Mobile Technology Requirements. If you are accessing our site and the Disclosures electronically via a mobile device (such as a smart phone, tablet, and the like), in addition to the above requirements you much make sure that you have software on your mobile device that allows you to print and save the Disclosures presented to you during the application process. These applications can be found for most mobile devices in the device‘s respective ”app store.” If you do not have these capabilities on your mobile device, please access our site through a device that provides these capabilities.
                    </p>
                    <p className='text-xl text-zinc-700 mb-3'>
                        Withdrawing Consent. You may withdraw your consent to receive Disclosures electronically by contacting us at the address below. However, once you have withdrawn your consent you will not be able to submit loan requests on our Site. If you have a pending loan request on our Site we will terminate it and remove it from our system. If you have already received a loan, all previously agreed to terms and conditions will remain in effect, and we will send Disclosures to your verified home address provided during registration.
                    </p>
                    <p className='text-xl text-zinc-700 mb-3'>
                        How to Contact Us Regarding Electronic Disclosures. You can contact us at: 855-FIN-WEST.
                        If you are an individual User, you will keep us informed of any change in your email or home mailing address so that you can continue to receive all Disclosures in a timely fashion. If your registered email address changes, you must notify us. You also agree to update your registered residence address and telephone number on the Site if they change.
                    </p>
                    <p className='text-xl text-zinc-700 mb-3'>
                        You will print a copy of this Agreement for your records and you agree and acknowledge that you can access, receive and retain all Disclosures electronically sent via email or posted on the site.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        LIMITATION OF LIABILITY
                    </h3>
                    <p className='text-xl text-zinc-700 mb-3'>
                        New West Financial WILL NOT BE LIABLE FOR ANY DAMAGES OR INJURY THAT ACCOMPANY OR RESULT FROM YOUR USE OF ANY OF ITS SITE.
                    </p>
                    <ul>
                        <li className='text-xl text-zinc-700 mb-1'>THESE INCLUDE (BUT ARE NOT LIMITED TO) DAMAGES OR INJURY CAUSED BY ANY:</li>
                        <li className='text-xl text-zinc-700 mb-1'>USE OF (OR INABILITY TO USE) THE SITE</li>
                        <li className='text-xl text-zinc-700 mb-1'>USE OF (OR INABILITY TO USE) ANY SITE TO WHICH YOU HYPERLINK FROM OUR SITE</li>
                        <li className='text-xl text-zinc-700 mb-1'>FAILURE OF OUR SITE TO PERFORM IN THE MANNER YOU EXPECTED OR DESIRED
                        </li>
                        <li className='text-xl text-zinc-700 mb-1'>ERROR ON OUR SITE</li>
                        <li className='text-xl text-zinc-700 mb-1'>OMISSION ON OUR SITE INTERRUPTION OF AVAILABILITY OF OUR SITE</li>
                        <li className='text-xl text-zinc-700 mb-1'>DEFECT ON OUR SITE</li>
                        <li className='text-xl text-zinc-700 mb-1'>DELAY IN OPERATION OR TRANSMISSION OF OUR SITE</li>
                        <li className='text-xl text-zinc-700 mb-1'>COMPUTER VIRUS OR LINE FAILURE</li>
                        <li className='text-xl text-zinc-700 mb-1'>PLEASE NOTE THAT WE ARE NOT LIABLE FOR ANY DAMAGES, INCLUDING:</li>
                        <li className='text-xl text-zinc-700 mb-1'>DAMAGES INTENDED TO COMPENSATE SOMEONE DIRECTLY FOR A LOSS OR INJURY</li>
                        <li className='text-xl text-zinc-700 mb-1'>DAMAGES REASONABLY EXPECTED TO RESULT FROM A LOSS OR INJURY (KNOWN IN LEGAL TERMS AS ”CONSEQUENTIAL DAMAGES.”)</li>
                        <li className='text-xl text-zinc-700 mb-1'>OTHER MISCELLANEOUS DAMAGES AND EXPENSES RESULTING DIRECTLY FROM A LOSS OR INJURY (KNOWN IN LEGAL TERMS AS ”INCIDENTAL DAMAGES.”)</li>
                        <li className='text-xl text-zinc-700 mb-1'>WE ARE NOT LIABLE EVEN IF WE‘VE BEEN NEGLIGENT OR IF OUR AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR BOTH.</li>
                    </ul>

                    <p className='text-xl text-zinc-700 mt-3 mb-3'>
                        <span className='font-semibold'>EXCEPTION:</span> CERTAIN STATE LAWS MAY NOT ALLOW US TO LIMIT OR EXCLUDE LIABILITY FOR THESE ”INCIDENTAL” OR ”CONSEQUENTIAL” DAMAGES. IF YOU LIVE IN ONE OF THOSE STATES, THE ABOVE LIMITATIONS DO NOT APPLY.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        PRIVACY
                    </h3>
                    <p className='text-xl text-zinc-700 mb-3'>
                        Please review the Site‘s Privacy Policy. By using the Site or the Service, you are consenting to have your personal data transferred to and processed in the United States.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        TERMINATION OF THIS AGREEMENT
                    </h3>
                    <p className='text-xl text-zinc-700 mb-3'>
                        This agreement is effective until terminated by either party. You may terminate this agreement at any time, by destroying all materials obtained from the New West
                        Financial site, along with all related documentation and all copies and installations. New West
                        Financial may terminate this agreement at any time and without notice to you, if, in its sole judgment, you breach any term or condition of this agreement. Upon termination, you must destroy all materials. In addition, by providing material on our Web site, we do not in any way promise that the materials will remain available to you. And New West
                        Financial is entitled to terminate all or any part of any of its Web site without notice to you.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        LINKS TO OTHER SITES
                    </h3>
                    <p className='text-xl text-zinc-700 mb-3'>
                        We sometimes provide referrals to and links to other World Wide Web sites from our site. Such a link should not be seen as an endorsement, approval or agreement with any information or resources offered at sites you can access through our site. If in doubt, always check the Uniform Resource Locator (URL) address provided in your internet browser to see if you are still in a New West
                        Financial-operated site or have moved to another site. New West
                        Financial is not responsible for the content or practices of third party sites that may be linked to our site. When New West
                        Financial provides links or references to other Web sites, no inference or assumption should be made and no representation should be inferred that New West
                        Financial is connected with, operates or controls these Web sites. Any approved link must not represent in any way, either explicitly or by implication, that you have received the endorsement, sponsorship or support of any New West
                        Financial site or endorsement, sponsorship or support of New West
                        Financial, including its respective employees, agents or directors.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        JURISDICTION AND OTHER POINTS TO CONSIDER
                    </h3>
                    <p className='text-xl text-zinc-700 mb-3'>
                        If you use our site from locations outside of the United States, you are responsible for compliance with any applicable local laws. These Terms of Use shall be governed by, construed and enforced in accordance with the laws of the State of Nevada, as it is applied to agreements entered into and to be performed entirely within such jurisdiction. To the extent you have in any manner violated or threatened to violate New West
                        Financial and/or its affiliates‘ intellectual property rights, New West
                        Financial and/or its affiliates may seek injunctive or other appropriate relief in any state or federal court in the State of Nevada, and you consent to exclusive jurisdiction and venue in such courts.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        INDEMNITY
                    </h3>
                    <p className='text-xl text-zinc-700 mb-3'>
                        You agree to indemnify and hold the Company, its subsidiaries and affiliates, and each of their directors, officers, agents, contractors, partners and employees, harmless from and against any loss, liability, claim, demand, damages, costs and expenses, including reasonable attorney‘s fees, arising out of or in connection with your User Content, any Third Party Content you post or share on or through the Site, your use of the Service or the Site, your conduct in connection with the Service or the Site or with other Users of the Service or the Site, or any violation of these Term of Use or of any law or the rights of any third party. The previous sentence shall not apply to any extent to Users while acting as an investor member or while registering as an investor member in connection with their (i) posting of User Content or (ii) use of the Site or Service.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        THE RESOLUTION OF OTHER DISPUTES
                    </h3>
                    <p className='text-xl text-zinc-700 mb-3'>
                        If a dispute arises under this agreement, we agree to first try to resolve it with the help of a mutually agreed-upon mediator in the following location: Sheridan County, Wyoming. Any costs and fees other than attorney fees associated with the mediation will be shared equally by each of us.
                    </p>
                    <p className='text-xl text-zinc-700 mb-3'>
                        If it proves impossible to arrive at a mutually satisfactory solution through mediation, we agree to submit the dispute to binding arbitration at the following location: Sheridan County, Wyoming, under the rules of the American Arbitration Association. Judgment upon the award rendered by the arbitration may be entered in any court with jurisdiction to do so. New West
                        Financial may modify these Terms of Use, and the agreement they create, at any time, simply by updating this posting and without notice to you. This is the ENTIRE agreement regarding all the matters that have been discussed.
                    </p>

                    <h3 className='text-2xl text-zinc-700 mb-2 font-semibold'>
                        OTHER
                    </h3>
                    <p className='text-xl text-zinc-700 mb-3'>
                        The failure of the Company to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision in that or any other instance. If any provision of these Terms of Use is held invalid, the remainder of these Terms of Use shall continue in full force and effect. If any provision of these Terms of Use shall be deemed unlawful, vid or for any reason unenforceable, then that provision shall be deemed severable from these Terms of Use and shall not affect the validity and enforceability of any remaining provisions.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Section02