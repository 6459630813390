import React from 'react'

function PrivacySection() {
    return (
        <section className="privacy-section mt-6 mb-14">
            <div className="container mx-auto w-full max-w-xl px-2 md:px-0">
                <div>
                    <p>
                        By providing my contact information and clicking on the “Continue” button above, I consent, acknowledge and agree to the following:
                    </p>
                    <ul className='my-4 pl-4 list-disc'>
                        <li className='mb-2'>Our Privacy Policy and Terms of Use and to receive important notices and other communications electronically.</li>
                        <li className='mb-2'>I am providing my express written consent to share my information with partners with whom New West Financial does business.</li>
                        <li className='mb-2'>I am providing my express written consent for New West Financial, its Affiliates, partners and authorized third parties calling on their behalf, to deliver calls or text messages to me, including for marketing purposes, using an automatic telephone dialing system or an artificial or pre-recorded voice to the phone number I have provided, even if my telephone number is currently listed on any federal, state, local, internal or corporate Do-Not-Call (“DNC”) List.</li>
                        <li className='mb-2'>
                            I understand that I do not have to agree to receive these types of calls or text messages as a condition of purchasing any goods or services. I understand that instead of providing consent, I may call 855-FIN-WEST to continue my application.
                        </li>
                        <li className='mb-2'>
                            I am providing express written consent under the Fair Credit Reporting Act for New West Financial and its partners to obtain my consumer credit report from my credit profile or other information from a Credit Bureau(s) in order to provide me with debt relief options without affecting my credit score.
                        </li>
                    </ul>
                    <p>Checking your rate won't affect your credit score!</p>
                </div>
            </div>
        </section>
    )
}

export default PrivacySection