import React from 'react'
import axios from "axios"

function DebtSection({ debtAmount }) {

    const handleSubmit = async (e) => {
        e.preventDefault()

        const body = {
            debtAmount: e.target.debtAmount.value,
            name: e.target.name.value,
            phone: e.target.phone.value,
            email: e.target.email.value,
            streetAddress: e.target.streetAddress.value,
            city: e.target.city.value,
            state: e.target.state.value,
            zipCode: e.target.zipCode.value
        }

        const { data } = await axios.post("https://sheltered-everglades-18033.herokuapp.com/contact/apply", body)

        if (data) {
            alert("Applied Successfully. We will get back to you max 24 hours")
            e.target.reset()
        }
    }

    return (
        <section className="debt-section my-12">
            <div className="container mx-auto max-w-md w-full px-2 md:px-0">
                <form onSubmit={handleSubmit}>
                    <h3 className="text-xl text-zinc-400 text-center">Select your debt amount:</h3>
                    <select name="debtAmount" id="" className='w-full py-2 px-6 rounded border-2 border-zinc-200 mt-2 mb-4'>
                        <option value="$3000-$5000"
                            selected={debtAmount > 3000 & debtAmount < 5000 ? true : false}>$3000-$5000</option>
                        <option value="$5000-$10000"
                            selected={debtAmount > 5000 & debtAmount < 10000 ? true : false}
                        >$5000-$10000</option>
                        <option value="$10000-$15000"
                            selected={debtAmount > 10000 & debtAmount < 15000 ? true : false}
                        >$10000-$15000</option>
                        <option value="$15000-$20000"
                            selected={debtAmount > 15000 & debtAmount < 20000 ? true : false}
                        >$15000-$20000
                        </option>
                        <option value="$20000-$30000"
                            selected={debtAmount > 20000 & debtAmount < 30000 ? true : false}
                        >$20000-$30000</option>
                        <option value="$30000-$40000"
                            selected={debtAmount > 30000 & debtAmount < 40000 ? true : false}
                        >$30000-$40000</option>
                        <option value="$40000-$50000"
                            selected={debtAmount > 40000 & debtAmount < 50000 ? true : false}
                        >$40000-$50000</option>
                        <option value="$50000-$60000"
                            selected={debtAmount > 50000 & debtAmount < 60000 ? true : false}
                        >$50000-$60000</option>
                        <option value="$60000-$70000"
                            selected={debtAmount > 60000 & debtAmount < 70000 ? true : false}
                        >$60000-$70000</option>
                        <option value="$70000-$80000"
                            selected={debtAmount > 70000 & debtAmount < 80000 ? true : false}
                        >$70000-$80000</option>
                        <option value="$80000-$90000"
                            selected={debtAmount > 80000 & debtAmount < 90000 ? true : false}
                        >$80000-$90000</option>
                        <option value="$90000-$100000"
                            selected={debtAmount > 90000 & debtAmount < 100000 ? true : false}
                        >$90000-$100000</option>
                        <option value="$100000+"
                            selected={debtAmount > 100000 ? true : false}
                        >$100000+</option>
                    </select>
                    <input type="text" name='name' placeholder='Name' className='my-2 border rounded py-1 px-4 text-md w-full outline-none' required />
                    <input type="text" name='phone' placeholder='Phone Number' className='my-2 border rounded py-1 px-4 text-md w-full outline-none' required />
                    <input type="text" name='email' placeholder='Email' className='my-2 border rounded py-1 px-4 text-md w-full outline-none' required />
                    <input type="text" name='streetAddress' placeholder='Street Address' className='my-2 border rounded py-1 px-4 text-md w-full outline-none' required />
                    <input type="text" name='city' placeholder='City' className='my-2 border rounded py-1 px-4 text-md w-full outline-none' required />
                    <input type="text" name='state' placeholder='State' className='my-2 border rounded py-1 px-4 text-md w-full outline-none' required />
                    <input type="text" name='zipCode' placeholder='ZIP Code' className='my-2 border rounded py-1 px-4 text-md w-full outline-none' required />
                    <button className='text-white bg-blue-600 text-center w-full py-2 px-4 rounded-full uppercase mt-4'>Submit</button>
                </form>
            </div>
        </section>
    )
}

export default DebtSection