import React from 'react'
import ContactSection from '../components/Home/ContactSection'
import Featured from '../components/Home/Featured'
import Hero from '../components/Home/Hero'
import Section03 from '../components/Home/Section03'
import Testimonials from '../components/Home/Testimonials'

function Home() {
    return (
        <>
            <Hero></Hero>
            <Featured></Featured>
            <Section03></Section03>
            <Testimonials></Testimonials>
            <ContactSection></ContactSection>
        </>
    )
}

export default Home