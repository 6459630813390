import React from 'react'

function Section02() {
    return (
        <section className="section-02 mb-16">
            <div className="container mx-auto px-2 md:px-0 max-w-3xl w-full">
                <div>
                    <h2 className="text-2xl md:text-4xl text-blue-600 font-semibold tracking-widest"
                        data-aos="fade-left" data-aos-delay="200"
                    >
                        BANKRUPTCY
                    </h2>
                    <p className="text-lg text-zinc-700 mt-8 mb-4"
                        data-aos="fade-left" data-aos-delay="200"
                    >
                        Bankruptcy is the condition in which an individual is unable to compensate their debtors. From 1980 to 2005 more than two million bankruptcy cases have been filed by debtors across the country. A bankruptcy involves a judge and that judge signs off on the terms of a bankruptcy. Not all bankruptcy terms are the same. There are several forms of bankruptcies and the laws around them continue to be updated. While New West Financial can not consult a customer on a bankruptcy, there are several side effects to a bankruptcy that make this option a last resort.
                    </p>
                    <p className="text-lg text-zinc-700 mt-8 mb-4"
                        data-aos="fade-left" data-aos-delay="200"
                    >
                        From a mortgage standpoint, a customer must wait 2-4 years to get a mortgage from the discharge date of the bankruptcy. In addition, a bankruptcy can stay on a customer’s credit for 7-10 years. Bankruptcy’s can hurt a customer’s chance of employment and/or jeopardize their current one. If you work in finance, law, law enforcement, the military, or government, many times it is required you disclose your bankruptcy history, and this could jeopardize a customer’s career options. 
                    </p>
                    <p className="text-lg text-zinc-700 mt-8 mb-4"
                        data-aos="fade-left" data-aos-delay="200"
                    >
                        Truth be told many financial experts discourage filing for bankruptcy to escape debt. If you are considering bankruptcy please consult an attorney. While New West Financial can not comment on the process of a bankruptcy for a customer’s situation, we strongly believe there are much better ways to get rid of debt and make a customer lendable again.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Section02