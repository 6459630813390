import React from 'react'
import BannerSection from '../components/BannerSection'
import HeroSection from '../components/HeroSection'
import WhyUsSection02 from '../components/WhyUs/WhyUsSection02'
import heroBgImg from "../images/WhyUs/whyus.jpg"
import bgImg from "../images/WhyUs/why-content.jpg"
import ContactSection from '../components/ContactSection'

function WhyUs() {

    const title = (
        <>
            WHY
            <br />
            CHOOSE US
        </>
    )

    return (
        <>
            <HeroSection bgImg={heroBgImg} title={title} ></HeroSection>
            <WhyUsSection02></WhyUsSection02>
            <BannerSection bgImg={bgImg} content={content}></BannerSection>
            <ContactSection></ContactSection>
        </>
    )
}

export default WhyUs


const content = (
    <>
        <h2 className="text-3xl text-zinc-700 mb-5 font-semibold">
            WE CUSTOMIZE OPTIONS TO YOU.
        </h2>
        <div className='mt-12'>
            <div className="my-8 flex items-center">
                <div className="opacity-40 text-blue-600 mr-4">
                    <h2 className='text-5xl font-medium'>1.</h2>
                </div>
                <div>
                    <h2 className="text-3xl font-medium text-blue-600">
                        CONTACT US
                    </h2>
                    <p className="text-xl">
                        A Consumer Finance Agent will help you figure out which plan is best for you!
                    </p>
                </div>
            </div>
            <div className="my-8 flex items-center">
                <div className="opacity-40 text-blue-600 mr-4">
                    <h2 className='text-5xl font-medium'>2.</h2>
                </div>
                <div>
                    <h2 className="text-3xl font-medium text-blue-600">
                        GET A QUOTE
                    </h2>
                    <p className="text-xl">
                        We will give you options, pick the one that works best for you!
                    </p>
                </div>
            </div>
            <div className="my-8 flex items-center">
                <div className="opacity-40 text-blue-600 mr-4">
                    <h2 className='text-5xl font-medium'>3.</h2>
                </div>
                <div>
                    <h2 className="text-3xl font-medium text-blue-600">
                        START SAVING
                    </h2>
                    <p className="text-xl">
                        Get rid of debt. Lower your payments. Restore Credit
                    </p>
                </div>
            </div>
        </div>
    </>
)