import { Route, Routes } from "react-router";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import WhyUs from "./pages/WhyUs";
import DebtSettlement from "./pages/DebtSettlement"
import MortgagePersonalLoans from "./pages/MortgagePersonalLoans"
import DebtResolution from "./pages/DebtResolution"
import Bankruptcy from "./pages/Bankruptcy"
import AboutUs from "./pages/AboutUs"
import TermsOfUse from "./pages/TermsOfUse"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import FAQs from "./pages/FAQs"
import Apply from "./pages/Apply"
import AOS from "aos"

function App() {

  AOS.init()

  return (
    <>
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home></Home>}></Route>
        <Route path="/why-us" element={<WhyUs></WhyUs>}></Route>
        <Route path="/debt-settlement" element={<DebtSettlement></DebtSettlement>}></Route>
        <Route path="/mortgage-personal-loans" element={<MortgagePersonalLoans></MortgagePersonalLoans>}></Route>
        <Route path="/debt-resolution" element={<DebtResolution></DebtResolution>}></Route>
        <Route path="/bankruptcy" element={<Bankruptcy></Bankruptcy>}></Route>
        <Route path="/about-us" element={<AboutUs></AboutUs>}></Route>
        <Route path="/terms-of-use" element={<TermsOfUse></TermsOfUse>}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy></PrivacyPolicy>}></Route>
        <Route path="/faq" element={<FAQs></FAQs>}></Route>
        <Route path="/contact-us" element={<Contact></Contact>}></Route>
        <Route path="/apply/:number" element={<Apply></Apply>}></Route>
        <Route path="*" element={<NotFound></NotFound>}></Route>
      </Routes>
      <Footer></Footer>
    </>
  );
}

export default App;
