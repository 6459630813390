import React from 'react'
import BannerSection from '../components/BannerSection'
import ContactSection from '../components/ContactSection'
import Section02 from '../components/DebtResolution/Section02'
import HeroSection from '../components/HeroSection'

import heroBgImg from "../images/DebtResolution/bg-debt-manage.jpg"
import bannerBgImg from "../images/DebtResolution/dm-content.jpg"

function DebtResolution() {

    const title = (
        <>
            DEBT
            <br />
            <span className='text-blue-600'>RESOLUTION</span>
        </>
    )

    const bannerContent = (
        <>
            <div className='pl-4 border-l-4 border-l-white text-white'>
                <h2 className="text-3xl font-medium tracking-widest">
                    ADVANTAGES AND <br /> DISADVANTAGES OF <br /> A DEBT <br /> RESOLUTION PLAN
                </h2>
                <p className="text-lg font-medium mt-10 mb-6">
                    The most obvious advantage of adopting a debt resolution plan is that it allows you to lower your interest rate while combining several bills into one responsibility. That said, however, you should be aware that there are disadvantages of debt resolution plans as well, including:
                </p>
                <div className="mt-6 pl-6">
                    <ul>
                        <li className='flex items-center my-1'>
                            <span className="inline-block h-4 w-4 rounded bg-white mr-2"></span>
                            <span className='text-lg font-medium'>Primarily for credit card debt</span>
                        </li>
                        <li className='flex items-center my-1'>
                            <span className="inline-block h-4 w-4 rounded bg-white mr-2"></span>
                            <span className='text-lg font-medium'>Unable to combine medical, tax, or student loans</span>
                        </li>
                        <li className='flex items-center my-1'>
                            <span className="inline-block h-4 w-4 rounded bg-white mr-2"></span>
                            <span className='text-lg  font-medium'>Takes three to five years to complete</span>
                        </li>
                        <li className='flex items-center my-1'>
                            <span className="inline-block h-4 w-4 rounded bg-white mr-2"></span>
                            <span className='text-lg  font-medium'>Unable to use credit cards while in program</span>
                        </li>
                        <li className='flex items-center my-1'>
                            <span className="inline-block h-4 w-4 rounded bg-white mr-2"></span>
                            <span className='text-lg  font-medium'>Missing one payment can derail entire plan</span>
                        </li>
                    </ul>
                </div>
                <p className="text-lg font-medium mt-10">
                    Even with these disadvantages however, working with a debt relief company can help you regain your financial footing in the face of steep credit card debt.
                </p>
            </div>
        </>
    )

    return (
        <>
            <HeroSection bgImg={heroBgImg} title={title}></HeroSection>
            <Section02></Section02>
            <BannerSection bgImg={bannerBgImg}></BannerSection>
            <ContactSection paddingTop="52" marginTop="mt-[-11rem]"></ContactSection>
        </>
    )
}

export default DebtResolution