import React from 'react'

function BannerSection({ bgImg, content }) {
    return (
        <section className="banner-section py-16"
            style={{
                background: `url(${bgImg})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                clipPath: "polygon(0 20%,100% 0,100% 80%,0 100%)",
                minHeight: "600px",
                marginBottom: "-8rem",
                position: "relative",
                zIndex: "999"
            }}>
            <div className="container mx-auto px-2 md:px-0">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className='pt-60 md:pt-28 pb-60 md:pb-16' data-aos="fade-right" data-aos-delay="200">
                        {content}
                    </div>
                    <div></div>
                </div>
            </div>
        </section>
    )
}

export default BannerSection