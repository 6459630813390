import React from 'react'
import { FaQuoteLeft } from "react-icons/fa"
import "./testimonial.css"

import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

function Testimonials() {
    return (
        <section className="testimonials-section">
            <div className="wrapper grid grid-cols-1 md:grid-cols-2">
                <div className="pl-0 md:pl-16 bg-blue-700 text-white py-16 ">
                    <div className='max-w-xs md:max-w-full mx-auto' data-aos="fade-up-right" data-aos-delay="200" >
                        <h2 className="text-4xl md:text-6xl">
                            TESTIMONIALS
                        </h2>
                        <h4 className="text-lg md:text-xl my-4">
                            SIMPLE PROCESS | SIMPLE APPROVAL | SIMPLE SOLUTIONS
                        </h4>
                        <h3 className="text-xl md:text-3xl">
                            Our team has helped more than 25,000 satisfied customers.
                        </h3>
                    </div>
                </div>
                <div className="pr-0 md:pr-16 bg-blue-700 text-white py-16 opacity-60 pl-4">
                    <Swiper
                        modules={[Navigation, Pagination]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                        onSlideChange={() => console.log("Slide change")}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide>
                            <div className='max-w-xs md:max-w-md w-full mx-auto' data-aos="fade-up-left" data-aos-delay="200">
                                <h2 className="text-6xl opacity-40 mb-6">
                                    <FaQuoteLeft />
                                </h2>
                                <p className='text-lg'>
                                    I really like the program because it was easy to get into: the monthly payments are things that I can manage and they're going to get me out of my predicament in a lot less time than if I tried to do it all by myself.
                                </p>
                                <p className='mt-4 text-lg font-medium text-right'>
                                    - Ron C.
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='max-w-xs md:max-w-md w-full mx-auto' data-aos="fade-up-left" data-aos-delay="200">
                                <h2 className="text-6xl opacity-40 mb-6">
                                    <FaQuoteLeft />
                                </h2>
                                <p className='text-lg'>
                                    Whether your tires go out, or something's wrong with your car, or something breaks in the house...I think New West Financial is a very family friendly place that just helps people who make honest mistakes. And they help them with debt in a very simple and easy way.
                                </p>
                                <p className='mt-4 text-lg font-medium text-right'>
                                    - David C.
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='max-w-xs md:max-w-md w-full mx-auto' data-aos="fade-up-left" data-aos-delay="200">
                                <h2 className="text-6xl opacity-40 mb-6">
                                    <FaQuoteLeft />
                                </h2>
                                <p className='text-lg'>
                                    I got a letter in the mail from New West Financial...I called and they seem to be able to talk to creditors about taking care of my debts. I wasn't even looking, but when the letter came, I said ‘this is a good idea.’ It was like a letter from Heaven came...that's the way I put it.
                                </p>
                                <p className='mt-4 text-lg font-medium text-right'>
                                    - June O.
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='max-w-xs md:max-w-md w-full mx-auto' data-aos="fade-up-left" data-aos-delay="200">
                                <h2 className="text-6xl opacity-40 mb-6">
                                    <FaQuoteLeft />
                                </h2>
                                <p className='text-lg'>
                                    I'm happy with the process. No matter who I get on the phone, they know my business...whatever notes they put in their computer system, no matter who I spoke to on the phone, I felt like I was getting personalized service. So I really liked that.
                                </p>
                                <p className='mt-4 text-lg font-medium text-right'>
                                    - Amanda L.
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='max-w-xs md:max-w-md w-full mx-auto' data-aos="fade-up-left" data-aos-delay="200">
                                <h2 className="text-6xl opacity-40 mb-6">
                                    <FaQuoteLeft />
                                </h2>
                                <p className='text-lg'>
                                    I can’t tell you how much I appreciate your help. I feel as though a weight has been lifted off of me and I can finally get out from under this debt. Thank you so much for your help.
                                </p>
                                <p className='mt-4 text-lg font-medium text-right'>
                                    - Ceasar F.
                                </p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default Testimonials