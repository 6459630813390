import React from 'react'

function Section02() {
    return (
        <section className="section-02 mb-16">
            <div className="container mx-auto px-2 md:px-0 max-w-3xl w-full">
                <div>
                    <h2 className="text-2xl md:text-4xl text-blue-600 font-semibold tracking-widest"
                        data-aos="fade-right" data-aos-delay="200"
                    >
                        DEBT SETTLEMENT
                    </h2>
                    <p className="text-lg text-zinc-700 mt-8 mb-4"
                        data-aos="fade-right" data-aos-delay="200"
                    >
                        Debt Settlement is just that: debts are settled by a third-party company on your behalf when a customer can not afford to pay back their creditors. Debt Settlement has been around for several decades, and most customers are familiar with the terminology. However less known are the side effects of debt settlement that make this option not very attractive.
                    </p>
                    <h3 className="text-xl md:text-2xl text-blue-600 font-semibold tracking-widest"
                        data-aos="fade-right" data-aos-delay="200"
                    >
                        On Your Own
                    </h3>
                    <p className="text-lg text-zinc-700 mb-4"
                        data-aos="fade-right" data-aos-delay="200"
                    >
                        If you work with a Debt Settlement company, almost always you are not working with a law firm. If a creditor sues you, they can take you to court. If a customer loses in court a judgment is put onto them and wages could be garnished.
                    </p>
                    <h3 className="text-xl md:text-2xl text-blue-600 font-semibold tracking-widest"
                        data-aos="fade-right" data-aos-delay="200"
                    >
                        Negative Credit History
                    </h3>
                    <p className="text-lg text-zinc-700 mb-4"
                        data-aos="fade-right" data-aos-delay="200"
                    >
                        If a customer does not make payments on a debt, they are going to have negative credit history even if they complete the debt settlement process. Debt settlement can not include credit restoration. A traditional debt settlement program usually lasts 48-60 months. After all that time, most customers are left unlendable again due to their credit history.
                    </p>
                    <h3 className="text-xl md:text-2xl text-blue-600 font-semibold tracking-widest"
                        data-aos="fade-right" data-aos-delay="200"
                    >
                        Cost
                    </h3>
                    <p className="text-lg text-zinc-700 mb-4"
                        data-aos="fade-right" data-aos-delay="200"
                    >
                        From our experience Debt Settlement is not cost effective. The cost of debt settlement is usually 50-55% of the debt, then there is the company’s fee which is usually 25%. In the end a customer usually ends up paying 75%-80% of the debt.
                    </p>
                    <h3 className="text-xl md:text-2xl text-blue-600 font-semibold tracking-widest"
                        data-aos="fade-right" data-aos-delay="200"
                    >
                        Not Guaranteed
                    </h3>
                    <p className="text-lg text-zinc-700 mb-4"
                        data-aos="fade-right" data-aos-delay="200"
                    >
                        Debt settlement is not guaranteed. Just because a customer enrolls into Debt Settlement does not mean a creditor is willing to settle. It usually takes 1-3 years for debt settlement company to start the actual negotiation process because there is no money in the beginning to settle the debt.
                    </p>
                    <p className="text-lg text-zinc-700 mb-4"
                        data-aos="fade-right" data-aos-delay="200"
                    >
                        All in all Debt Settlement is not recommended.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Section02