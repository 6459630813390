import React from 'react'

function Section02() {
    return (
        <section className="section-02 mb-16">
            <div className="container mx-auto px-2 md:px-0 max-w-3xl w-full">
                <div className = 'mt-16'>
                    <p className="text-lg text-zinc-700 mt-8 mb-4" data-aos="fade-in-right" data-aos-delay="200">
                        The purpose of this page and the follow pages is to provide relevant information on the most common ways to get out of debt. There are a LOT of terms used in consumer finance that can get very confusing very fast.
                    </p>
                </div>
                <div className = 'mt-16'>
                    <p className="text-lg text-zinc-700 mt-8 mb-4" data-aos="fade-in-right" data-aos-delay="200">
                        This information comes for our extensive experience in and around loans, as well as from our lending partners and service providers. Every credit report and every circumstance is different and can’t be generalized. To determine the best solution to your circumstance please contact one of our highly trained Consumer Finance Agents at 855-NEW-WEST.
                    </p>
                </div>
                <div className = 'mt-16'>
                    <h2 className="text-2xl md:text-4xl text-blue-600 font-semibold tracking-widest" data-aos="fade-up-right" data-aos-delay="200">
                        Mortgages
                    </h2>
                    <p className="text-lg text-zinc-700 mt-8 mb-4" data-aos="fade-in-right" data-aos-delay="200">
                        Mortgages and personal loans are traditionally the best way to get rid of debt. In general credit cards come with an average interest rate of 20%+ and that’s if you have good credit! Additionally minimum credit card payments are based on a 30-year payback term. That’s long!
                        <br /><br />
                        Mortgages usually come with the lowest rate in the lending world because it is ‘collateralized deb’, meaning it is secured to a home. Mortgages are a great way to pay off debt if you can get a low rate and term that works for you. They usually provide much lower payments compared to the accumulative debts you are trying to pay off. 
                        <br /> <br />
                        The most common mortgage is a 30-year fix followed by the 15 -year fix. There are also HELOC’s (home equity line of credit) and HELOANS (fixed 2nd mortgages) that can also work to pay off debt and usually come with a slightly higher rate than a first mortgage due to the increased risk of being in the 2nd lien position. 
                        <br /><br />
                        Weather it’s a first mortgage or second mortgage option that is most appealing to you, the most effective way to pay off debt through the mortgage is to budget some of the savings and pay down the mortgage quicker. There are many types of mortgage options out there so make sure to talk to one of our Agents and be put in contact with a licensed loan officer. 
                    </p>
                </div>
                <div className = 'mt-16'>
                    <h2 className="text-2xl md:text-4xl text-blue-600 font-semibold tracking-widest" data-aos="fade-up-right" data-aos-delay="200">
                        Personal Loans
                    </h2>
                    <p className="text-lg text-zinc-700 mt-8 mb-4" data-aos="fade-in-right" data-aos-delay="200">
                        Unlike mortgages, personal loans are ‘uncollateralized debt’ meaning it is not secured to anything (such as a house or a car). This is a great short-term option for homeowners and non-homeowners alike. Personal loans make the most sense if a customer can get one with a low interest rate and term that fits their budget. Personal loans are based on credit score and income, so homeowners and non-homeowners alike don’t have to worry about home equity or homeownership status. 
                        <br /><br />
                        Like mortgages when you consolidate debt with a personal loan the idea is to consolidation into one payment and end up paying less that what you are currently paying now. Speak with one of our Consumer Finance Agents today to determine your eligibility.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Section02