import React from 'react'
import Section02 from '../components/DebtSettlement/Section02'
import HeroSection from "../components/HeroSection"
import BannerSection from "../components/BannerSection"
import ContactSection from "../components/ContactSection"

import heroBgImg from "../images/DebtSettlement/bg-debt-sett.jpg"
import bannerBgImg from "../images/DebtSettlement/ds-content.jpg"

function DebtSettlement() {

    const title = (
        <>
            DEBT
            <br />
            <span className='text-slate-900'>SETTLEMENT</span>
        </>
    )

    const bannerContent = (
        <>
            <div className='pl-4 border-l-4 border-l-white text-white'>
                <h2 className="text-3xl font-medium">
                    ADVANTAGES OF DEBT SETTLEMENT SERVICES
                </h2>
                <div className="my-6 pl-6">
                    <ul>
                        <li className='flex items-center my-1'>
                            <span className="inline-block h-4 w-4 rounded bg-white mr-2"></span>
                            <span className='text-lg font-medium'>Avoiding bankruptcys</span>
                        </li>
                        <li className='flex items-center my-1'>
                            <span className="inline-block h-4 w-4 rounded bg-white mr-2"></span>
                            <span className='text-lg font-medium'>Relief from crippling debt</span>
                        </li>
                        <li className='flex items-center my-1'>
                            <span className="inline-block h-4 w-4 rounded bg-white mr-2"></span>
                            <span className='text-lg  font-medium'>How Can Debt Settlement Companies Help</span>
                        </li>
                    </ul>
                </div>
                <p className="text-lg font-medium">
                    When you are ready to take control of your debt, and you think that a debt settlement solution is the best course of action, reach out to our team here at New West Financial to discuss your potential options. We’ll discuss the best debt settlement solution plan for your individual situation and craft a plan that works for you.
                </p>
            </div>
        </>
    )

    return (
        <>
            <HeroSection bgImg={heroBgImg} title={title} ></HeroSection>
            <Section02></Section02>
            <BannerSection bgImg={bannerBgImg}></BannerSection>
            <ContactSection></ContactSection>
        </>
    )
}

export default DebtSettlement