import React from 'react'
import HeroSection from "../components/HeroSection"
import ContactSection from "../components/Contact/ContactSection"

import heroBgImg from "../images/Home/bgsection.jpg"

function Contact() {

    const title = (
        <>
            CONTACT
            <span className='text-blue-600'>US</span>
        </>
    )

    return (
        <>
            <HeroSection bgImg={heroBgImg} title={title}></HeroSection>
            <ContactSection></ContactSection>
        </>
    )
}

export default Contact