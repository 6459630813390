import React from 'react'
import { Link } from 'react-router-dom'

function ContactSection() {
    return (
        <section className="contact-section py-24" style={{
            background: `url(${require("../../images/Home/bgsection.jpg")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
        }}>
            <div className="container mx-auto px-2 md:px-0">
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <div data-aos="fade-in-right" data-aos-delay="200">
                        <h2 className='text-3xl md:text-6xl font-medium text-blue-600'>
                            TALK WITH US.<br />
                            WE ARE HERE TO<br />
                            HELP.
                        </h2>
                        <h4 className='text-xl md:text-3xl my-6'>
                            MORE MONEY TO LIVE <br />LIFE.
                        </h4>
                        <p className='text-md md:text-2xl text-black font-extrabold md:font-normal'>
                        When you’re trying to pay off your credit card, even making the minimum amount can feel overwhelming. Our proven process can reduce your credit card payments, putting your budget back on track and keeping your money where it belongs – with you.
                        </p>
                        <div className='my-6'>
                            <h3 className='text-xl md:text-3xl'>CALL US</h3>
                            <a href="tel:855-FIN-WEST" className='font-bold text-3xl md:text-6xl text-blue-600'>855-FIN-WEST</a>
                        </div>
                        <Link to="/contact" className='text-uppercase border-2 border-blue-600 py-4 px-12 block w-full text-center text-blue-600 hover:bg-blue-600 hover:text-white rounded-full text-2xl font-medium'>Contact Us</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactSection