import React from 'react'
import HeroSection from "../components/HeroSection"
import Section02 from '../components/TermsOfUse.jsx/Section02'
import heroBgImg from "../images/Home/bg-map-grey-scaled.jpg"

function TermsOfUse() {

    const title = (
        <>
            TERMS OF USE
        </>
    )

    return (
        <>
            <HeroSection bgImg={heroBgImg} title={title}></HeroSection>
            <Section02></Section02>
        </>
    )
}

export default TermsOfUse