import React from 'react'
import { FaEnvelope, FaPhone } from 'react-icons/fa'
import Logo from '../images/Home/NewWestFinancial.png';
function Footer() {


    return (
        <footer className='footer border-t-4 border-t-blue-600 bg-slate-200 py-12'>
            <div className="container mx-auto px-2 md:px-0">
                <div className="wrapper">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
                        <div>
                             <img src={Logo} alt="New West Financial" height = "auto"
                             width = "60px" />
                            <h4 className="text-lg my-4"> &copy; {new Date().getFullYear()} New West Financial ALL RIGHTS RESERVED.</h4>
                            <p className="text-sm">
                                All trademarks, service marks, trade names, product names, logos and trade dress appearing on our website are the property of their respective owners.
                            </p>
                        </div>
                        <div className='text-center'>
                            <h2 className="text-3xl text-blue-600 font-medium">
                                CONTACT
                            </h2>
                            <a href="tel:855-FIN-WEST" className="flex items-center justify-center w-full my-4 text-blue-500 text-md">
                                <FaPhone className='mr-3' />
                                <span>855-FIN-WEST</span>
                            </a>
                            <a href="mailto:info@NewWestFinancial.com " className="flex items-center justify-center w-full  text-blue-500 text-md">
                                <FaEnvelope className='mr-3' />
                                <span>info@NewWestFinancial.com </span>
                            </a>
                        </div>
                        <div>
                            <h2 className="text-3xl text-blue-600 font-medium">
                                HOURS
                            </h2>
                            <ul className='mt-4'>
                                <li className={`border-b-2 border-b-blue-300 text-md py-2 px-3 ${new Date().getDay() == 0 && "bg-blue-600 text-white"}`}>
                                    <span className='mr-2 '>SUNDAY</span>
                                    <span>CLOSED</span>
                                </li>
                                <li className={`border-b-2 border-b-blue-300 text-md py-2 px-3 ${new Date().getDay() == 1 && "bg-blue-600 text-white"}`}>
                                    <span className='mr-2 '>MONDAY</span>
                                    <span>8:00 AM — 5:00 PM</span>
                                </li>
                                <li className={`border-b-2 border-b-blue-300 text-md py-2 px-3 ${new Date().getDay() == 2 && "bg-blue-600 text-white"}`}>
                                    <span className='mr-2 '>TUESDAY</span>
                                    <span>8:00 AM — 5:00 PM</span>
                                </li>
                                <li className={`border-b-2 border-b-blue-300 text-md py-2 px-3 ${new Date().getDay() == 3 && "bg-blue-600 text-white"}`}>
                                    <span className='mr-2 '>WEDNESDAY</span>
                                    <span>8:00 AM — 5:00 PM</span>
                                </li>
                                <li className={`border-b-2 border-b-blue-300 text-md py-2 px-3 ${new Date().getDay() == 4 && "bg-blue-600 text-white"}`}>
                                    <span className='mr-2 '>THURSDAY</span>
                                    <span>8:00 AM — 5:00 PM</span>
                                </li>
                                <li className={`border-b-2 border-b-blue-300 text-md py-2 px-3 ${new Date().getDay() == 5 && "bg-blue-600 text-white"}`}>
                                    <span className='mr-2 '>FRIDAY</span>
                                    <span>8:00 AM — 5:00 PM</span>
                                </li>
                                <li className={`border-b-2 border-b-blue-300 text-md py-2 px-3 ${new Date().getDay() == 6 && "bg-blue-600 text-white"}`}>
                                    <span className='mr-2 '>SATURDAY</span>
                                    <span>CLOSED</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='mt-12'>
                    <p className="text-md">
                        By clicking "Get In Touch" I agree to receive recurring automated marketing text messages from New West Financial at the phone number provided. Message/data rates apply. Consent is not a condition of purchase. View our Terms of Service and Privacy Policy.
                    </p>
                    <p className="text-md">
                        If you wish to be removed from our marketing please call 855-FIN-WEST or contact Info@NewWestFinancial.com.                    
                    </p>
                    <p className="text-md">
                        *Please note that all calls with the company may be recorded or monitored for quality assurance and training purposes. All claims are based on enrolled debts. Not all debts are eligible for enrollment. Not all clients are able to complete our program for various reasons, including their ability to save sufficient funds. Our estimates are based on our own professional experience and prior achieved results, which will vary depending on your specific circumstances. We do not guarantee that your debts will be resolved for a specific amount or percentage or within a specific period of time. We do not assume your debts, make monthly payments to your creditors or provide tax, bankruptcy, accounting or legal advice or credit repair services. Our service is not available in all states and our fees may vary from state to state. Please contact a tax professional to discuss potential tax consequences of less than full balance debt resolution. Read and understand all program materials prior to enrollment. Taking on any new debt, or not paying any debt might adversely affect your creditworthiness, may result in you being subject to collections or being sued by creditors or collectors and may increase the outstanding balances of your enrolled accounts due to the accrual of fees and interest. However, negotiated settlements we obtain on your behalf resolve the entire account, including all accrued fees and interest.
                    </p>
                    <p className="text-md">
                        All members shall not discriminate – and shall make available to prospective customers notice that they do not discriminate – on the basis of race, color, religion, sex, marital status, national origin or ancestry.
                    </p>
                    <p className="text-md">
                        ** Under our debt resolution program, our estimates are based on prior results, which will vary depending on your individual program terms and your specific enrolled creditors. We cannot guarantee that we will be able to resolve all of your enrolled accounts or that we will be able to resolve them for a specific amount or within a specific period of time. Not all of our clients will be able to complete our program for any number of reasons, including, but not limited to, their ability to save enough money during the term of their program. Under our program, we do not assume the liability for your enrolled accounts, and we do not make monthly payments to your creditors. Failing to make timely payments to your creditors on your enrolled accounts will likely adversely affect your creditworthiness, increase the outstanding balances due to the accrual of interest charges, penalties and late fees, and may subject you to collection actions or lawsuits by your creditors. However, the repayment agreements that we may negotiate on your behalf will resolve the entire outstanding balance on that account, including any interest, penalties or fees accrued during your program participation. Any amount of debt forgiven by your creditors may be subject to income tax, and you should consult with an accountant or attorney regarding the potential tax consequences of paying less than the full amount owed on your enrolled accounts. We do not provide tax, accounting, legal or bankruptcy advice. We are not a credit repair company, and we do not provide credit repair services. You should carefully read all program materials prior to your enrollment to be sure you fully understand the terms and conditions of our program. Our program is not available in all states, and our fees vary by state.
                    </p>
                    <p className="text-md">
                        *** Certain types of debts are not eligible for enrollment.
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer