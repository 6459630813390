import React from 'react'
import { useParams } from 'react-router-dom'
import DebtSection from '../components/Apply/DebtSection';
import PrivacySection from '../components/Apply/PrivacySection';

function Apply() {

    const params = useParams()

    return (
        <>
            <DebtSection debtAmount={params.number}></DebtSection>
            <PrivacySection></PrivacySection>
        </>
    )
}

export default Apply