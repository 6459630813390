import React from 'react'
import HeroSection from "../components/HeroSection"
import ContactSection from "../components/ContactSection"

import heroBgImg from "../images/Faq/faq-bg.jpg"
import Faq from '../components/Faq/Faq'

function FAQs() {

    const title = (
        <>
            FAQS
        </>
    )

    return (
        <>
            <HeroSection bgImg={heroBgImg} title={title}></HeroSection>
            <Faq></Faq>
            <ContactSection paddingTop="24"></ContactSection>
        </>
    )
}

export default FAQs