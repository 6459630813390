import React from 'react'

function Faq() {
    return (
        <section className="faq-section mb-80 md:mb-72">
            <div className="container mx-auto px-2 md:px-0 max-w-3xl w-full">
                <div>
                    <h2 className="text-4xl text-blue-600 font-semibold my-8 tracking-widest text-center"
                        data-aos="fade-left" data-aos-delay="200"
                    >
                        FREQUENTLY ASKED QUESTIONS
                    </h2>
                    <p className='text-xl'
                        data-aos="fade-right" data-aos-delay="200"
                    >Please give us a call at <a href="tel:855-FIN-WEST" className='text-blue-600'>855-FIN-WEST</a>, if you don’t see the answer you are looking for. We have some of the most frequently asked questions about debt relief below.</p>

                    <div className="mt-12" data-aos="zoom-in" data-aos-delay="200">
                        <div tabIndex={0} className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
                            <input type="checkbox" className="peer" />
                            <div className="collapse-title text-xl font-medium">
                                What is a debt resolution program?
                            </div>
                            <div className="collapse-content">
                                <p>
                                    Debt resolution (aka debt settlement, negotiated debt settlement, or debt negotiation) is a process where a company (like New West Financial) negotiates or settles an unsecured debt to a creditor or debt collector.

                                    The debt resolution company has two goals when they’re negotiating with your creditors:

                                    <ul className="pl-4">
                                        <li>1. to reduce the amount you owe and</li>
                                        <li>2. to “settle” the debt more quickly than you’d pay it off on your own.</li>
                                    </ul>

                                    While that may seem too good to be true, the debt resolution process is pretty standard for creditors... many often settle debt for less than the amount owed. Creditors know that clients facing hardship may never pay the debt or decide to declare bankruptcy, so it’s in the creditor’s own interest to settle the debt for less rather than getting no money at all.
                                </p>
                            </div>
                        </div>
                        <div tabIndex={1} className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
                            <input type="checkbox" className="peer" />
                            <div className="collapse-title text-xl font-medium">
                                Who is best suited for a debt resolution program?
                            </div>
                            <div className="collapse-content">
                                <p>
                                    Debt resolution is generally for people with $10,000 or more in unsecured debt who want to reduce their total amount of debt without declaring bankruptcy. If you’re facing financial hardship and have more debt than you can pay off in the next two to four years, debt resolution can be a solid option for getting yourself back on track financially.
                                </p>
                            </div>
                        </div>
                        <div tabIndex={2} className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
                            <input type="checkbox" className="peer" />
                            <div className="collapse-title text-xl font-medium">
                                What type of debt can New West Financial help me with?
                            </div>
                            <div className="collapse-content">
                                <p>
                                    We only work with debts without collateral attached to them, also known as unsecured debt. Unsecured debt includes everything from credit cards to store cards and medical bills. Debts with collateral attached –like mortgages, car loans, or federal student loans– are not eligible for debt resolution. Get in touch with us if you need to clarify which of your debts are eligible.
                                </p>
                            </div>
                        </div>
                        <div tabIndex={3} className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
                            <input type="checkbox" className="peer" />
                            <div className="collapse-title text-xl font-medium">
                                What about calls from collectors? How should I handle them?
                            </div>
                            <div className="collapse-content">
                                <p>
                                    Creditors hire collectors to pressure you into paying as much as they can get, and they’ll then pocket a percentage of whatever you pay your creditors. Collection calls are a natural part of debt resolution programs, and are actually a key indicator that your debt resolution program is working! Our most successful clients choose to let calls from unrecognized numbers go to voicemail. There are even free apps you can download on a smartphone to block certain calls. Thanks to federal and state laws, you have rights against collection harassment (click here for more FTC guidelines about those laws and your rights). If you accidentally end up speaking to a collector, you should let the collector know you’re working with New West Financial, so they should call us instead of you. Then, make sure to let us know about the call, including which collector is calling you and when they called you. We want to get your creditors to call us if they’re looking to receive payment, not you.


                                </p>
                            </div>
                        </div>
                        <div tabIndex={4} className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
                            <input type="checkbox" className="peer" />
                            <div className="collapse-title text-xl font-medium">
                                What happens if my creditors take legal action against me?
                            </div>
                            <div className="collapse-content">
                                <p>
                                    Legal action by creditors could occur. If you do receive a legal notice, please send it to our service team so they can prioritize this creditor or lender and work to settle it first. Rather than taking legal action, a more common step creditors take is selling your debt to third-party collection agencies and/or law firms. When something like this happens, this particular creditor or lender might get moved to a priority list. If a lawsuit does get filed against you, your settlement negotiators can attempt to resolve that creditor or lender’s account by setting up a specific payment plan. They also may be able to refer you for further help if needed. Note: We are not a law firm and can’t offer any legal advice.
                                </p>
                            </div>
                        </div>
                        <div tabIndex={5} className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
                            <input type="checkbox" className="peer" />
                            <div className="collapse-title text-xl font-medium">
                                Am I going to owe taxes on whatever debt was forgiven?
                            </div>
                            <div className="collapse-content">
                                <p>
                                    While the answer can be different for everyone, in general, forgiven debts can be taxable. When tax time rolls around, you’ll likely receive a Cancellation of Debt form (1099-c) from the lender that forgave your debt. To find out about your specific situation –and understand the potential tax implications of any debt that’s been forgiven through a debt relief program– it’s a good idea to talk to your tax advisor.
                                </p>
                            </div>
                        </div>
                        <div tabIndex={6} className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
                            <input type="checkbox" className="peer" />
                            <div className="collapse-title text-xl font-medium">
                                How could a debt resolution program impact my credit?
                            </div>
                            <div className="collapse-content">
                                <p>
                                    Most client credit is already being negatively impacted by poor payment history and amounts owed, which are the two largest factors of your credit. Whether your credit is maxed out or you have a high debt-to-credit or debt-to-income ratio, chances are that your credit has been negatively impacted. Our goal is to help you resolve your debt quickly, so that you can start building a brighter financial future (including a more positive credit score) as soon as possible. Everyone’s specific credit situation is different, but in general, enrolling in any debt resolution program could have a negative impact on your credit. The good news is that, while you may take one step back with your credit, you’ll take five steps forward with resolving debt. In the long term, the credit effect could be much more positive; Once your debts are resolved, you can practice positive credit behavior to build your credit score back up over time.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faq