import React from 'react'

function Section02() {
    return (
        <section className="about-us-section mb-72 md:mb-72">
            <div className="container mx-auto px-2 md:px-0">
                <div className="max-w-3xl w-full mx-auto">
                    <h2 className="text-4xl text-blue-600 font-semibold "
                        data-aos="fade-right" data-aos-delay="200"
                    >
                        HAVE DEBT?
                    </h2>
                    <p className="mt-12 mb-8 text-xl text-zinc-700"
                        data-aos="fade-left" data-aos-delay="200"
                    >
                        If you’re in debt and trying to determine the best way to pay it off, you have several different options. In addition to a couple do-it-yourself strategies, your other choices include a debt consolidation loan, credit counseling, debt settlement, a cash-out refinance, or bankruptcy.
                    </p>
                    <p className=" text-xl text-zinc-700"
                        data-aos="fade-left" data-aos-delay="200"
                    >
                        If you’re wondering “which one is right for me?”, the answer depends on how much you owe, what type of debt you have, and a few other factors. We help resolve your debt and take the pressure off you. End your debt with our reduction programs.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Section02