import React from 'react'
import { Link } from 'react-router-dom'

function ContactSection({ heading, text, paddingTop, marginTop }) {
    return (
        <section className={`contact-section pt-[16rem] md:pt-72 mt-[-18rem] md:mt-[-13rem] `} style={{
            background: `url(${require("../images/Home/bg-map-grey-scaled.jpg")})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
        }}>
            <div className="container mx-auto px-2 md:px-0">
                <div className={`pb-24 pt-${paddingTop ? paddingTop : "40"} text-center`} data-aos="zoom-in" data-aos-delay="200">
                    <h2 className='text-white text-4xl font-semibold tracking-widest'>
                        {heading ? heading : <>READY TO BE DEBT FREE WITH <span className='text-blue-600'>New West Financial</span>?</>}
                    </h2>
                    <h3 className="text-2xl my-8 text-white">
                        {text ? text : <>
                            We’re here to help you along to better finances. Call us today at <a href="tel:855-FIN-WEST" className='text-blue-700 font-semibold'>855-FIN-WEST</a> .
                        </>}
                    </h3>
                    <Link to="/contact-us" className='text-uppercase font-medium text-2xl text-white bg-blue-600 rounded-full pt-3 pb-4 px-16 mx-auto inline-block'>contact us</Link>
                </div>
            </div>
        </section >
    )
}

export default ContactSection