import React from 'react'

function Section02() {
    return (
        <section className="section-02 mb-16">
            <div className="container mx-auto px-2 md:px-0 max-w-3xl w-full">
                <div>
                    <h2 className="text-2xl md:text-4xl text-blue-600 font-semibold tracking-widest"
                        data-aos="fade-right" data-aos-delay="200"
                    >
                        DEBT RESOLUTION
                    </h2>
                    <p className="text-lg text-zinc-700 mt-8 mb-4"
                        data-aos="fade-left" data-aos-delay="200"
                    >
                        Debt Resolution is a great option that aims to eliminate uncollateralized debt in a short amount of time with much lower payments. Unlike loans (mortgages and personal loans), a customer’s credit score is not taken into consideration. Instead, your debts must qualify, not the customer’s credit. The debts can only be uncollateralized debts and not secured debts or students loans.
                    </p>
                    <p className="text-lg text-zinc-700 mb-4"
                        data-aos="fade-left" data-aos-delay="200"
                    >
                        What sets Debt Resolution apart from all the other options is not only does it aim to get rid of the actual balance of the debt, but also removes negative credit history with the debt (if there is any). In essence, debt resolution allows customers a ‘fresh start’ with their credit.
                    </p>
                    <p className="text-lg text-zinc-700 "
                        data-aos="fade-left" data-aos-delay="200"
                    >
                        If there was an procedural order to all the options for eliminating debt, getting a low rate loan would be first, followed by Debt Resolution. There are circumstances however where Debt Resolution is the most appropriate option even if a customer does qualify for a low-rate loan. Please contact our Consumer Finance Agents to explore what option is best for you.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Section02