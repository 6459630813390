import React from 'react'
import BannerSection from '../components/BannerSection'
import ContactSection from '../components/ContactSection'
import HeroSection from '../components/HeroSection'

import heroBgImg from "../images/Bankruptcy/bg-bankruptcy.jpg"
import bannerBgImg from "../images/Bankruptcy/bank-content.jpg"
import Section02 from '../components/Bankruptcy/Section02'

function Bankruptcy() {

    const title = (
        <>
            <span className='text-blue-600'>BANKRUPTCY</span>
        </>
    )

    const bannerContent = (
        <>
            <div className='pl-4 border-l-4 border-l-white text-white'>
                <h2 className="text-3xl font-medium tracking-widest">
                    BANKRUPTCY LAW IS <br />ON YOUR SIDE
                </h2>
                <p className="text-lg font-medium mt-10 mb-6">
                    Surprisingly, bankruptcy is an essential fabric in the United States Constitution. The American founders were fixated on creating a system in which individuals burdened with a massive debt could relieve themselves and build a fresh start. According to the U.S. Bankruptcy Code, here are ways people can choose to file bankruptcy.
                </p>
                <div className="mt-6 pl-6">
                    <ul>
                        <li className='flex items-center my-1'>
                            <span className="inline-block h-4 w-4 rounded bg-white mr-2"></span>
                            <span className='text-lg font-medium'>Liquidation – This is the basic chapter of bankruptcy. In this process, the court will assign a trustee to oversee the liquidation of all of your assets to pay your debtors. Though, this doesn’t necessarily pertain to your vital possessions, such as your primary residence or vehicle.</span>
                        </li>
                        <li className='flex items-center my-1'>
                            <span className="inline-block h-4 w-4 rounded bg-white mr-2"></span>
                            <span className='text-lg font-medium'>Adjustment of Debts – For those who don’t apply to liquidation due to income constraints, this process entails paying off your debtors within three to five years, using your regular income and other assets.</span>
                        </li>
                        <li className='flex items-center my-1'>
                            <span className="inline-block h-4 w-4 rounded bg-white mr-2"></span>
                            <span className='text-lg  font-medium'>Reorganization – In this process, debtors are mandated by the court to submit a plan to reorganize their finances to pay back creditors in 120 days. This chapter allows individuals to pay back their debt and afford their living expenses.</span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )

    const contactHeading = (
        <>
            WHAT TO DO BEFORE FILING FOR <br /> BANKRUPTCY
        </>
    )
    const contactText = (
        <>
            As you can see, there are several options if you plan to file bankruptcy. To discover whether bankruptcy is the right course for you, contact AcuFi to learn all your options. . Call us today at
            <a href="tel:855-FIN-WEST" className='text-blue-600'>855-FIN-WEST</a>
        </>
    )

    return (
        <>
            <HeroSection bgImg={heroBgImg} title={title}></HeroSection>
            <Section02></Section02>
            <BannerSection bgImg={bannerBgImg}></BannerSection>
            <ContactSection paddingTop="52" marginTop="mt-[-11rem]"></ContactSection>
        </>
    )
}

export default Bankruptcy