import React from 'react'
import axios from "axios"


function ContactSection() {


    const handleSubmit = async (e) => {
        e.preventDefault()

        const body = {
            email: e.target.email.value,
            name: e.target.name.value,
            phone: e.target.number.value,
            message: e.target.message.value
        }

        const { data: sendEmail } = await axios.post("https://sheltered-everglades-18033.herokuapp.com/contact", body)

        if (sendEmail.message == "Message sent") {
            alert("Email has been sent")
            e.target.reset()
        }
    }

    return (
        <section className="contact-section mt-16 mb-24">
            <div className="container mx-auto max-w-3xl w-full px-2 md:px-0">
                <h2 className="text-center text-blue-600 font-medium tracking-widest mb-12 text-3xl">
                    CONTACT FORM
                </h2>
                <p className="text-xl">
                    Whenever you have a question or need information about our lending services, just reach out — give us a call or send an email. We do our best to answer emails the same day. We welcome your feedback. Just fill out and submit this online comment card:
                </p>
                <form onSubmit={handleSubmit} className="max-w-md w-full mx-auto p-2 mt-8">
                    <div className="mb-6">
                        <input type="text" name='name' className="text-md text-zinc-600 rounded shadow w-full py-2 px-5" placeholder='Name*' required />
                        {/* <span className='text-red-400 font-semibold text-xl mb-3 mt-2'>Name is required</span> */}
                    </div>
                    <div className="mb-6">
                        <input type="number" name='number' className="text-md text-zinc-600 rounded shadow w-full py-2 px-5" placeholder='Phone Number*' required />
                        {/* <span className='text-red-400 font-semibold text-xl mb-3 mt-2'>Name is required</span> */}
                    </div>
                    <div className="mb-6">
                        <input type="email" name='email' className="text-md text-zinc-600 rounded shadow w-full py-2 px-5" placeholder='Your Email*' required />
                        {/* <span className='text-red-400 font-semibold text-xl mb-3 mt-2'>Name is required</span> */}
                    </div>
                    <div className="mb-6">
                        <textarea name="message" cols="30" rows="6"
                            className="text-md text-zinc-600 rounded shadow w-full py-2 px-5"
                            placeholder='Leave a message here'
                            required
                        >

                        </textarea>
                        {/* <span className='text-red-400 font-semibold text-xl mb-3 mt-2'>Name is required</span> */}
                    </div>
                    <div className="my-4">
                        <button className="text-white text-medium text-xl text-center w-full rounded-full bg-blue-600 py-2 px-8 uppercase opacity-80">Get in touch</button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default ContactSection