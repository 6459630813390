import React from 'react'
import HeroSection from "../components/HeroSection"
import ContactSection from "../components/ContactSection"
import heroBgImg from "../images/AboutUs/about.jpg"
import Section02 from '../components/AboutUs/Section02'

function AboutUs() {

    const title = (
        <>
            ABOUT US
        </>
    )

    return (
        <>
            <HeroSection bgImg={heroBgImg} title={title}></HeroSection>
            <Section02></Section02>
            <ContactSection paddingTop="24"></ContactSection>
        </>
    )
}

export default AboutUs